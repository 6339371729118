// src/components/Footer/Footer.tsx

import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import HoverLink from '../HoverLink';

const Footer: React.FC = () => {
  return (
    <Box component="footer" color="black" p={4} sx={{pt: 8}}>
      <Grid container spacing={4} justifyContent="space-between" alignItems="center">

        <Grid item xs={12} md={3}>
          <Box
            component="img"
            src="https://s3.timeweb.cloud/b510c84d-test/logos/logo_no_bg.png"
            alt="Логотип компании"
            sx={{
              height: {xs: '120px', sm: '140px', md: '160px'},
              objectFit: 'contain',
            }}
          />
        </Grid>

        {/* Навигация */}
        <Grid item xs={12} md={3}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <HoverLink
              to="/catalog"
              label="Каталог"
              underlineColor="black"
              color="inherit"
              variant="body1"
              sx={{ fontSize: '20px', mb: 2, maxWidth: 'fit-content' }}
            />
            <HoverLink
              to="/company"
              label="Компания"
              underlineColor="black"
              color="inherit"
              variant="body1"
              sx={{ fontSize: '20px', mb: 2, maxWidth: 'fit-content' }}
            />
            <HoverLink
              to="/contacts"
              label="Контакты"
              underlineColor="black"
              color="inherit"
              variant="body1"
              sx={{ fontSize: '20px', mb: 2, maxWidth: 'fit-content' }}
            />
            <HoverLink
              to="/how-to-buy"
              label="Доставка и оплата"
              underlineColor="black"
              color="inherit"
              variant="body1"
              sx={{ fontSize: '20px', mb: 2, maxWidth: 'fit-content' }}
            />
            <HoverLink
              to="/services"
              label="Услуги"
              underlineColor="black"
              color="inherit"
              variant="body1"
              sx={{ fontSize: '20px', mb: 2, maxWidth: 'fit-content' }}
            />
            <HoverLink
              to="/videos"
              label="Видео"
              underlineColor="black"
              color="inherit"
              variant="body1"
              sx={{ fontSize: '20px', mb: 2, maxWidth: 'fit-content' }}
            />
          </Box>
        </Grid>

        {/* Контактная информация */}
        <Grid item xs={12} md={3}>
          <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
            Телефон в Шанхае:
          </Typography>
          <Box sx={{ mb: 2 }}>
            <HoverLink to="tel:+8615502106310" label="+86 155-021-063-10" underlineColor="black" color="inherit" variant="body1" />
          </Box>

          <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 2 }}>
            Телефоны в РФ:
          </Typography>
          <Box sx={{ mb: 1 }}>
            <HoverLink to="tel:+79108364995" label="+7 910 836-49-95" underlineColor="black" color="inherit" variant="body1" />
          </Box>
          <Box>
            <HoverLink to="tel:+79109351152" label="+7 910 935-11-52" underlineColor="black" color="inherit" variant="body1" />
          </Box>

          <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 2 }}>
            Email:
          </Typography>
          <Box>
            <HoverLink to="mailto:yuzhen.torg@gmail.com" label="yuzhen.torg@gmail.com" underlineColor="black" color="inherit" variant="body1" />
          </Box>
        </Grid>
      </Grid>

      <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 2 }}>
        © 2024 Yuzhen. Все права защищены.
      </Typography>

      <Typography 
        variant="caption" 
        color="textSecondary" 
        align="center" 
        sx={{ 
          mt: 2, 
          display: 'block',
          maxWidth: '100%',
          px: 2
        }}
      >
        Вы принимаете условия{' '}
        <HoverLink
          to="/politika.docx"
          label="политики в отношении обработки персональных данных"
          color="textSecondary"
          variant="caption"
          download
        />{' '}
        и{' '}
        <HoverLink
          to="/user_agreement.docx"
          label="пользовательского соглашения"
          color="textSecondary"
          variant="caption"
          download
        />{' '}
        каждый раз, когда оставляете свои данные в любой форме обратной связи на сайте{' '}
        <HoverLink
          to="https://yuzhen.store/"
          label="https://yuzhen.store/"
          color="textSecondary"
          variant="caption"
        />
      </Typography>

      <Typography 
        variant="caption" 
        color="textSecondary" 
        align="center" 
        sx={{ 
          mt: 1, 
          display: 'block',
          maxWidth: '100%',
          px: 2
        }}
      >
        Обращаем ваше внимание на то, что данный интернет-сайт, а также вся информация о товарах и ценах, предоставленная на нём, носит исключительно информационный характер и ни при каких условиях не является публичной офертой, определяемой положениями Статьи 437 Гражданского кодекса Российской Федерации.
      </Typography>
    </Box>
  );
};

export default Footer;
