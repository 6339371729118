import { configureStore } from '@reduxjs/toolkit';
import catalogReducer from './slices/catalogSlice';
import productReducer from './slices/productSlice'
import searchReducer from './slices/searchSlice';

const store = configureStore({
  reducer: {
    catalog: catalogReducer,
    products: productReducer,
    search: searchReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
