// src/components/HowToBuy/PaymentSection.tsx

import React from 'react';
import { Typography, Paper, Box, Divider } from '@mui/material';
import { Fade } from '@mui/material';

const PaymentSection: React.FC = () => {
  return (
    <Fade in={true} timeout={1000}>
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          marginBottom: 4,
          borderRadius: 8,
          transition: 'box-shadow 0.5s ease',
          boxShadow: 3,
          '&:hover': {
            boxShadow: 6,
          },
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
          Условия и способы оплаты
        </Typography>

        <Divider sx={{ mb: 2 }} />

        <Typography variant="body1" sx={{ mb: 2 }}>
          Оплата товара осуществляется только безналичным переводом денег согласно выставленному счету. Мы предлагаем различные условия оплаты по согласованию сторон. Ниже указаны наши стандартные условия оплат, если оборудование поставляется под заказ:
        </Typography>

        {/* Оформим список с жирным выделением и минимальными отступами */}
        <Box component="div" sx={{ ml: 2 }}>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            50% — предоплата
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            40% — по факту готовности к отгрузке со склада
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            10% — после ввода оборудования в эксплуатацию
          </Typography>
        </Box>

        <Typography variant="body1" sx={{ mt: 2 }}>
          Счета выставляются в рублях или китайских юанях. Оплата счетов в юанях производится по курсу ЦБ РФ.
        </Typography>
      </Paper>
    </Fade>
  );
};

export default PaymentSection;
