// src/contexts/NavbarStyleContext.tsx

import React, { createContext, useContext, useState, ReactNode } from 'react';

type NavbarStyle = 'light' | 'dark';

interface NavbarStyleContextProps {
  navbarStyle: NavbarStyle;
  setNavbarStyle: (style: NavbarStyle) => void;
}

const NavbarStyleContext = createContext<NavbarStyleContextProps | undefined>(undefined);

export const useNavbarStyle = (): NavbarStyleContextProps => {
  const context = useContext(NavbarStyleContext);
  if (!context) {
    throw new Error('useNavbarStyle должен использоваться внутри NavbarStyleProvider');
  }
  return context;
};

interface NavbarStyleProviderProps {
  children: ReactNode;
}

export const NavbarStyleProvider: React.FC<NavbarStyleProviderProps> = ({ children }) => {
  const [navbarStyle, setNavbarStyle] = useState<NavbarStyle>('dark'); // Значение по умолчанию

  return (
    <NavbarStyleContext.Provider value={{ navbarStyle, setNavbarStyle }}>
      {children}
    </NavbarStyleContext.Provider>
  );
};
