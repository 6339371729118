import React, { useEffect, useState } from 'react';
import { Card, CardMedia, Box, Dialog, IconButton, DialogContent, Typography } from '@mui/material';
import CarouselList from '../CarouselList';
import { Close, ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';

interface ProductImagesProps {
  images: string[];
}

const Images: React.FC<ProductImagesProps> = ({ images }) => {
  const [open, setOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleOpen = (index: number) => {
    setCurrentImageIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        handlePrev();
      } else if (event.key === 'ArrowRight') {
        handleNext();
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [open, currentImageIndex]);

  return (
    <Box sx={{ mb: 4 }}>
      <CarouselList slidesToShow={{ xs: 1, sm: 2, md: 3, lg: 4 }}>
        {images.map((img, index) => (
          <Card
            key={index}
            sx={{
              margin: '0 10px',
              height: '300px',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              boxShadow: 3,
              transition: 'box-shadow 0.3s ease-in-out',
              '&:hover': {
                boxShadow: 6,
              }
            }}
            onClick={() => handleOpen(index)}
          >
            <CardMedia
              component="img"
              image={img}
              alt={`Изображение продукта ${index + 1}`}
              sx={{ maxHeight: '100%', objectFit: 'contain' }}
            />
          </Card>
        ))}
      </CarouselList>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          },
        }}
        sx={{
          '.MuiDialog-paper': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            position: 'relative',
          }
        }}
      >
        <DialogContent sx={{ position: 'relative', p: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              color: 'white',
              zIndex: 1,
              backgroundColor: 'transparent',
            }}
          >
            <Close />
          </IconButton>

          <IconButton
            onClick={handlePrev}
            sx={{
              position: 'absolute',
              left: 10,
              color: 'white',
              zIndex: 1,
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
              },
            }}
          >
            <ArrowBackIosNew />
          </IconButton>

          <img
            src={images[currentImageIndex]}
            alt={`Изображение продукта ${currentImageIndex + 1}`}
            style={{ maxHeight: '80vh', objectFit: 'contain' }}
          />

          <IconButton
            onClick={handleNext}
            sx={{
              position: 'absolute',
              right: 10,
              color: 'white',
              zIndex: 1,
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
              },
            }}
          >
            <ArrowForwardIos />
          </IconButton>
        </DialogContent>

        <Typography variant="body2" sx={{ position: 'absolute', bottom: 10, left: '50%', transform: 'translateX(-50%)', color: 'white' }}>
          {currentImageIndex + 1} / {images.length}
        </Typography>
      </Dialog>
    </Box>
  );
};

export default Images;
