import React from 'react';
import { Typography, List, ListItem, ListItemText, IconButton, Box } from '@mui/material';
import { Download } from '@mui/icons-material';

interface ProductFileBlockProps {
  title?: string;
  files: { name: string; url: string }[];
}

const FileBlock: React.FC<ProductFileBlockProps> = ({ title, files }) => {
  return (
    <Box>
      {title && (
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
      )}
      <List>
        {files.map((file, index) => (
          <ListItem
            key={index}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: 0,
            }}
          >
            <ListItemText primary={file.name} />
            <IconButton
              href={file.url}
              download
              sx={{ color: 'primary.main' }}
            >
              <Download />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default FileBlock;
