// src/components/Contacts/Contacts.tsx

import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import ContactSection from './ContactSection';
import RepresentativeSection from './RepresentativeSection';
import BankDetailsSection from './BankDetailsSection';
import { Fade, Zoom } from '@mui/material';

const Contacts: React.FC = () => {
  return (
    <Container maxWidth="md" sx={{ paddingY: 5 }}>
      <Typography variant="h3" align="center" gutterBottom>
        Контакты
      </Typography>

      <Fade in={true} timeout={1000}>
        <Box>
          <ContactSection />
        </Box>
      </Fade>

      <Zoom in={true} timeout={1000}>
        <Box>
          <RepresentativeSection />
        </Box>
      </Zoom>

      <Fade in={true} timeout={1000}>
        <Box>
          <BankDetailsSection />
        </Box>
      </Fade>
    </Container>
  );
};

export default Contacts;
