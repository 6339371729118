import React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { keyframes } from '@mui/system';

interface LoadingAnimationProps {
  message?: string;
}

const pulse = keyframes`
  0% {
    transform: scale(0.95);
    opacity: 0.5;
  }
  50% {
    transform: scale(1);
    opacity: 0.8;
  }
  100% {
    transform: scale(0.95);
    opacity: 0.5;
  }
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingAnimation: React.FC<LoadingAnimationProps> = ({ message = 'Загрузка...' }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '200px',
        gap: 2,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          animation: `${pulse} 2s ease-in-out infinite`,
        }}
      >
        <CircularProgress
          size={60}
          thickness={4}
          sx={{
            animation: `${rotate} 1.5s linear infinite`,
            color: 'primary.main',
          }}
        />
        <CircularProgress
          size={40}
          thickness={4}
          sx={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            marginLeft: '-20px',
            marginTop: '-20px',
            animation: `${rotate} 2s linear infinite reverse`,
            color: 'secondary.main',
          }}
        />
      </Box>
      <Typography
        variant="h6"
        sx={{
          opacity: 0.8,
          animation: `${pulse} 2s ease-in-out infinite`,
          textAlign: 'center',
        }}
      >
        {message}
      </Typography>
    </Box>
  );
};

export default LoadingAnimation; 