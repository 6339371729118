// src/components/Contacts/BankDetailsSection.tsx

import React, { useState } from 'react';
import { Typography, Paper, List, ListItem, ListItemText, Box, Divider, IconButton, Tooltip, Snackbar, Alert } from '@mui/material';
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import { Fade } from '@mui/material';
import HoverLink from '../HoverLink';

const BankDetailsSection: React.FC = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [copiedText, setCopiedText] = useState<string>(''); // Для отображения скопированного текста
  const [snackbarKey, setSnackbarKey] = useState(0); // Ключ для перерисовки Snackbar

  const copyText = `
    БИК: 044525411
    Банк: ФИЛИАЛ "ЦЕНТРАЛЬНЫЙ" БАНКА ВТБ (ПАО)
    Корреспондентский счёт: 30101810145250000411
    Расчётный счёт: 40802810412010002171
  `;

  const handleCopyAll = () => {
    setCopiedText('банковские реквизиты');
    setOpenSnackbar(false);
    setSnackbarKey((prevKey) => prevKey + 1);
    setTimeout(() => {
      navigator.clipboard.writeText(copyText.trim());
      setOpenSnackbar(true);
    }, 100);
  };

  const handleCopy = (text: string) => {
    setCopiedText(text);
    setOpenSnackbar(false);
    setSnackbarKey((prevKey) => prevKey + 1);
    setTimeout(() => {
      navigator.clipboard.writeText(text);
      setOpenSnackbar(true);
    }, 100);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <Fade in={true} timeout={1000}>
        <Paper
          elevation={3}
          sx={{
            padding: 4,
            marginBottom: 4,
            borderRadius: 8,
            transition: 'box-shadow 0.5s ease',
            boxShadow: 3,
            '&:hover': {
              boxShadow: 6,
            },
            position: 'relative',
          }}
        >
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: 3 }}>
            Банковские реквизиты
            <Tooltip title="Скопировать все реквизиты" arrow>
              <IconButton
                onClick={handleCopyAll}
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  color: 'inherit',
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Typography>

          <Divider sx={{ mb: 2 }} />

          <List>
            <ListItem>
              <ListItemText
                primary={
                  <Box component="span">
                    БИК:{' '}
                    <Tooltip title="Нажмите, чтобы скопировать БИК" arrow>
                      <HoverLink
                        to="#"
                        label="044525411"
                        underlineColor="black"
                        color="inherit"
                        variant="body1"
                        onClick={() => handleCopy('044525411')}
                      />
                    </Tooltip>
                  </Box>
                }
                sx={{ textAlign: 'center' }}
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary={
                  <Box component="span">
                    Банк:{' '}
                    <Tooltip title="Нажмите, чтобы скопировать название банка" arrow>
                      <HoverLink
                        to="#"
                        label='ФИЛИАЛ "ЦЕНТРАЛЬНЫЙ" БАНКА ВТБ (ПАО)'
                        underlineColor="black"
                        color="inherit"
                        variant="body1"
                        onClick={() => handleCopy('ФИЛИАЛ "ЦЕНТРАЛЬНЫЙ" БАНКА ВТБ (ПАО)')}
                      />
                    </Tooltip>
                  </Box>
                }
                sx={{ textAlign: 'center' }}
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary={
                  <Box component="span">
                    Корреспондентский счёт:{' '}
                    <Tooltip title="Нажмите, чтобы скопировать Корр. счёт" arrow>
                      <HoverLink
                        to="#"
                        label="30101810145250000411"
                        underlineColor="black"
                        color="inherit"
                        variant="body1"
                        onClick={() => handleCopy('30101810145250000411')}
                      />
                    </Tooltip>
                  </Box>
                }
                sx={{ textAlign: 'center' }}
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary={
                  <Box component="span">
                    Расчётный счёт:{' '}
                    <Tooltip title="Нажмите, чтобы скопировать Расчётный счёт" arrow>
                      <HoverLink
                        to="#"
                        label="40802810412010002171"
                        underlineColor="black"
                        color="inherit"
                        variant="body1"
                        onClick={() => handleCopy('40802810412010002171')}
                      />
                    </Tooltip>
                  </Box>
                }
                sx={{ textAlign: 'center' }}
              />
            </ListItem>
          </List>
        </Paper>
      </Fade>

      {/* Snackbar для уведомления */}
      <Snackbar
        key={snackbarKey}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Скопировано: {copiedText}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BankDetailsSection;
