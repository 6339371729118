import React from 'react';
import { Typography } from '@mui/material';

interface ProductTitleProps {
  name: string;
  level?: number;
}

const Title: React.FC<ProductTitleProps> = ({ name, level }) => {
  let fontSize = { xs: '7vw', sm: '36px', md: '42px' };
  let fontWeight = '800';
  let variant: any = 'h3';
  let component: any = 'h1';

  if (level) {
    switch (level) {
      case 1:
        fontSize = { xs: '6vw', sm: '32px', md: '36px' };
        fontWeight = '800';
        variant = 'h1';
        component = 'h1';
        break;
      case 2:
        fontSize = { xs: '5.5vw', sm: '28px', md: '32px' };
        fontWeight = '700';
        variant = 'h2';
        component = 'h2';
        break;
      case 3:
        fontSize = { xs: '5vw', sm: '24px', md: '28px' };
        fontWeight = '600';
        variant = 'h3';
        component = 'h3';
        break;
      case 4:
        fontSize = { xs: '4.5vw', sm: '20px', md: '24px' };
        fontWeight = '500';
        variant = 'h4';
        component = 'h4';
        break;
      case 5:
        fontSize = { xs: '4vw', sm: '16px', md: '20px' };
        fontWeight = '400';
        variant = 'h5';
        component = 'h5';
        break;
      case 6:
        fontSize = { xs: '3.5vw', sm: '14px', md: '16px' };
        fontWeight = '300';
        variant = 'h6';
        component = 'h6';
        break;
      default:
        break;
    }
  }

  return (
    <Typography
      variant={variant}
      component={component}
      gutterBottom
      sx={{
        mt: 4,
        mb: 4,
        textAlign: 'center',
        fontWeight: fontWeight,
        fontSize: fontSize,
      }}
    >
      {name}
    </Typography>
  );
};

export default Title;
