import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';
import { Category } from './catalogSlice';
import { IProduct } from './productSlice';

interface SearchResults {
  categories: Category[];
  products: IProduct[];
}

interface SearchState {
  results: SearchResults;
  loading: boolean;
  error: string | null;
}

const initialState: SearchState = {
  results: {
    categories: [],
    products: []
  },
  loading: false,
  error: null
};

export const searchCatalog = createAsyncThunk(
  'search/searchCatalog',
  async (query: string) => {
    try {
      const { data } = await api.get(`/api/categories/search/?q=${encodeURIComponent(query)}`);
      return data as SearchResults;
    } catch (error: any) {
      throw new Error('Ошибка при поиске');
    }
  }
);

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    clearSearch: (state) => {
      state.results = initialState.results;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchCatalog.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchCatalog.fulfilled, (state, action) => {
        state.loading = false;
        state.results = action.payload;
      })
      .addCase(searchCatalog.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Ошибка поиска';
      });
  },
});

export const { clearSearch } = searchSlice.actions;
export default searchSlice.reducer; 