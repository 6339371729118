import React from 'react';
import { Box, List, ListItemButton, ListItemText, Typography, Grow } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface ServicesMenuProps {
  anchorEl: HTMLElement | null;
  isOpen: boolean;
  onClose: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  isSticky: boolean;
}

const ServicesMenu: React.FC<ServicesMenuProps> = ({
  anchorEl,
  isOpen,
  onClose,
  onMouseEnter,
  onMouseLeave,
  isSticky,
}) => {
  const navigate = useNavigate();
  const companyRedColor = '#D32F2F';

  const menuItems = [
    { 
      title: 'Прайс лист',
      id: 'прайс-лист-на-услуги-по-работе-с-китаем',
      description: 'Стоимость услуг по работе с Китаем'
    },
    { 
      title: 'Бизнес-тур в Китай',
      id: 'бизнестур-в-китай-по-индивидуальному-маршруту',
      description: 'Организация поездки на фабрики'
    },
    { 
      title: 'Поиск поставщика',
      id: 'поиск-поставщика',
      description: 'Поможем найти производителя в Китае'
    }
  ];

  const handleClick = (id: string) => {
    navigate('/services', { state: { scrollTo: id } });
    onClose();
  };

  const getMenuPosition = () => {
    if (anchorEl) {
      const rect = anchorEl.getBoundingClientRect();
      const parentRect = anchorEl.offsetParent?.getBoundingClientRect();
      return {
        top: parentRect ? rect.bottom - parentRect.top : rect.bottom,
        left: parentRect ? rect.left - parentRect.left : rect.left,
      };
    }
    return { top: 0, left: 0 };
  };

  return (
    <Grow in={isOpen} timeout={300} style={{ transformOrigin: '0 0 0' }}>
      <Box
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        sx={{
          position: 'absolute',
          top: getMenuPosition().top,
          left: getMenuPosition().left,
          width: '300px',
          display: 'flex',
          backgroundColor: 'transparent',
          borderRadius: '10px',
          overflow: 'hidden',
          zIndex: 1300,
          color: 'black',
          '&::before': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: -1,
          },
        }}
      >
        <List sx={{ width: '100%', padding: 1 }}>
          {menuItems.map((item) => (
            <ListItemButton
              key={item.id}
              onClick={() => handleClick(item.id)}
              sx={{
                padding: 1,
                color: 'inherit',
                textDecoration: 'none',
                transition: 'color 0.3s ease',
                '&:hover': {
                  color: companyRedColor,
                  backgroundColor: 'rgba(255, 255, 255, 0.4)',
                },
              }}
            >
              <ListItemText
                primary={item.title}
                secondary={item.description}
                primaryTypographyProps={{
                  variant: 'h6',
                  sx: {
                    fontWeight: 'bold',
                    color: 'inherit',
                  },
                }}
                secondaryTypographyProps={{
                  sx: {
                    color: 'inherit',
                    opacity: 0.7,
                  },
                }}
              />
            </ListItemButton>
          ))}
        </List>
      </Box>
    </Grow>
  );
};

export default ServicesMenu; 