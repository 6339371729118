// src/components/HowToBuy/DeliverySection.tsx

import React, { useState } from 'react';
import { Typography, Paper, Box, Divider, Popover, Snackbar, Alert } from '@mui/material';
import { Fade } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HoverLink from '../HoverLink';

const DeliverySection: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openSnackbar, setOpenSnackbar] = useState(false); // Добавляем состояние для Snackbar
  const [copiedText, setCopiedText] = useState<string>(''); // Для отображения, что именно скопировано

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopiedText(text);
    setOpenSnackbar(true);
    handleClosePopover();
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const open = Boolean(anchorEl);

  return (
    <Fade in={true} timeout={1000}>
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          marginBottom: 4,
          borderRadius: 8,
          transition: 'box-shadow 0.5s ease',
          boxShadow: 3,
          '&:hover': {
            boxShadow: 6,
          },
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
          Доставка
        </Typography>

        <Divider sx={{ mb: 2 }} />

        <Typography variant="body1" sx={{ mb: 2 }}>
          Доставка оборудования производится за счет средств заказчика по всей территории РФ и стран СНГ, транспортными компаниями по согласованию с заказчиком.
        </Typography>

        <Typography variant="body1">
          Самовывоз оборудования осуществляется по адресу:{' '}
          <HoverLink
            label="Тверская область, г. Удомля, ул. Совхозная, д. 29 «А»"
            onClick={handleOpenPopover}
            underlineColor="black"
            variant="body1"
            sx={{ fontWeight: 'bold' }}
          />
        </Typography>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          sx={{
            borderRadius: 8
          }}
        >
          <Box sx={{ p: 2 }}>
            <HoverLink
              label="Адрес"
              onClick={() => handleCopy('Тверская область, г. Удомля, ул. Совхозная, д. 29 «А»')}
              underlineColor="black"
              variant="body1"
            />
            <HoverLink
              label="Геолокация"
              onClick={() => handleCopy('57.870844, 34.989614')}
              underlineColor="black"
              variant="body1"
              sx={{ ml: 2 }}
            />
          </Box>
        </Popover>

        <Typography variant="body1" sx={{ mt: 2 }}>
          При получении товара необходимо иметь при себе доверенность или печать компании.
        </Typography>

        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            <AccessTimeIcon fontSize="small" sx={{ mr: 1 }} />
            Время работы склада
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Понедельник - Пятница: 9:00 - 18:00
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Суббота и Воскресенье — выходные дни
          </Typography>
        </Box>

        {/* Snackbar для уведомления */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000} // Длительность отображения (3 сек)
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Положение Snackbar на экране
        >
          <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
            Скопировано: {copiedText}
          </Alert>
        </Snackbar>
      </Paper>
    </Fade>
  );
};

export default DeliverySection;
