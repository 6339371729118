import React from 'react';
import { IContentBlock } from '../../store/slices/productSlice';
import TextBlock from './TextBlock';
import TableBlock from './TableBlock';
import ListBlock from './ListBlock';
import FileBlock from './FileBlock';
import Title from './Title';
import { Divider } from '@mui/material';

interface ProductContentBlockProps {
  block: IContentBlock;
}

const ContentBlock: React.FC<ProductContentBlockProps> = ({ block }) => {
  switch (block.type) {
    case 'text':
      return <TextBlock content={block.content} title={block.title} />;
    case 'table':
      return <TableBlock TableBlockData={block} />;
    case 'list':
      return <ListBlock title={block.title} items={block.items} />;
    case 'file':
      return <FileBlock title={block.title} files={block.files} />;
    case 'title':
      return <Title name={block.text} level={block.level} />;
    case 'combined':
      return (
        <>
          {block.content.map((block: IContentBlock, index: number) => {
            return (
              <ContentBlock block={block} key={index} />
            );
          })}
        </>
      );
    case 'divider':
      return <Divider sx={{ m: 1 }} />
    default:
      return null;
  }
};

export default ContentBlock;
