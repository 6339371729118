import React, { useEffect, useRef } from 'react';
import Services from '../components/Services/Services';
import { useNavbarStyle } from '../components/NavbarStyleContext';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

const ServicesPage: React.FC = () => {
  const { setNavbarStyle } = useNavbarStyle();
  const location = useLocation();
  const headerHeight = 90;

  useEffect(() => {
    setNavbarStyle('light');

    const scrollTo = (location.state as any)?.scrollTo;
    if (scrollTo) {
      setTimeout(() => {
        const element = document.getElementById(scrollTo);
        if (element) {
          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - headerHeight;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
      }, 300);
    }

    return () => {
      setNavbarStyle('dark');
    };
  }, [setNavbarStyle, location]);

  return (
    <Services />
  );
};

export default ServicesPage;
