import React, { useEffect, useRef } from 'react';
import {
  Typography,
  Box,
  Container,
  CircularProgress,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { fetchCategories } from '../store/slices/catalogSlice';
import { fetchProductsByCategory } from '../store/slices/productSlice';
import { useParams } from 'react-router-dom';
import { Category } from '../store/slices/catalogSlice';
import CatalogHeader from '../components/Catalog/CatalogHeader';
import ProductList from '../components/Catalog/ProductList';
import CategoryList from '../components/Catalog/CategoryList';
import HoverLink from '../components/HoverLink';
import LoadingAnimation from '../components/LoadingAnimation';

const CatalogPage: React.FC = () => {
  const { categoryId } = useParams<{ categoryId?: string }>();
  const dispatch = useAppDispatch();
  const { categories, loading, error } = useAppSelector((state) => state.catalog);
  const productsByCategory = useAppSelector((state) => state.products.byCategory);

  const catalogRef = useRef<HTMLDivElement | null>(null);
  const navBarHeight = 90;

  useEffect(() => {
    if (categories.length === 0) {
      dispatch(fetchCategories());
    }
  }, [dispatch, categories.length]);

  const scrollToContent = () => {
    if (catalogRef.current) {
      const offsetTop =
        catalogRef.current.getBoundingClientRect().top +
        window.pageYOffset -
        navBarHeight;
      window.scrollTo({ top: offsetTop, behavior: 'smooth' });
    }
  };

  if (loading) {
    return <LoadingAnimation message="Загрузка категорий..." />;
  }

  if (error) {
    return <Typography color="error">Ошибка: {error}</Typography>;
  }

  const findCategoryById = (
    id: string,
    categoryList: Category[],
  ): Category | null => {
    for (const category of categoryList) {
      if (category.id === id) {
        return category;
      }
      if (category.children) {
        const found = findCategoryById(id, category.children);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  const findCategoryPathById = (
    id: string,
    categoryList: Category[],
    path: Category[] = []
  ): Category[] | null => {
    for (const category of categoryList) {
      const newPath = [...path, category];
      if (category.id === id) {
        return newPath;
      }
      if (category.children) {
        const found = findCategoryPathById(id, category.children, newPath);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  const selectedCategory = categoryId
    ? findCategoryById(categoryId, categories)
    : null;

  if (categoryId && !selectedCategory) {
    return <Typography>Категория не найдена</Typography>;
  }

  const isFinalCategory = (category: Category) =>
    !category.children || category.children.length === 0;

  const loadProductsForCategory = (categoryId: string) => {
    const categoryData = productsByCategory[categoryId];
    if (!categoryData && !productsLoading(categoryId)) {
      dispatch(fetchProductsByCategory(categoryId));
    }
    return categoryData?.products || [];
  };

  const productsLoading = (categoryId: string) => {
    return productsByCategory[categoryId]?.loading || false;
  };

  const productsError = (categoryId: string) => {
    return productsByCategory[categoryId]?.error || null;
  };

  const categoryPath = categoryId
    ? findCategoryPathById(categoryId, categories)
    : null;

  return (
    <>
      <CatalogHeader
        categoryPath={categoryPath}
        selectedCategory={selectedCategory}
        scrollToContent={scrollToContent}
      />

      <Container maxWidth="xl">
        <Box p={2}>
          <Box ref={catalogRef}>
            {selectedCategory ? (
              isFinalCategory(selectedCategory) ? (
                <Box>
                  <Typography variant="h4" mb={2}>
                    {`Товары категории "${selectedCategory.name}"`}
                  </Typography>
                  {productsLoading(selectedCategory.id) ? (
                    <LoadingAnimation message="Загрузка товаров..." />
                  ) : productsError(selectedCategory.id) ? (
                    <Typography color="error">
                      {`Ошибка загрузки товаров: ${productsError(selectedCategory.id)}`}
                    </Typography>
                  ) : loadProductsForCategory(selectedCategory.id).length > 0 ? (
                    <ProductList products={loadProductsForCategory(selectedCategory.id)} />
                  ) : (
                    <Typography>Нет товаров в этой категории</Typography>
                  )}
                </Box>
              ) : (
                <Box>
                  <Typography variant="h4" mb={2}>
                    <HoverLink
                      to={`/catalog/${selectedCategory.id}`}
                      label={selectedCategory.name}
                      color="inherit"
                      underlineColor="black"
                      variant="h4"
                    />
                  </Typography>
                  <CategoryList categories={selectedCategory.children!}/>
                </Box>
              )
            ) : (
              <CategoryList categories={categories} />
            )}
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default CatalogPage;
