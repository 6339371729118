import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Container, IconButton, Box, Slide } from '@mui/material';
import { Menu as MenuIcon, Search as SearchIcon } from '@mui/icons-material';
import { useAppDispatch } from '../../store/hooks';
import { fetchCategories } from '../../store/slices/catalogSlice';
import NavbarLinks from './NavbarLinks';
import Logo from './Logo';
import ContactInfo from './ContactInfo';
import { useNavbarStyle } from '../NavbarStyleContext';
import NavBarMobile from './NavBarMobile';
import RequestButton from '../RequestButton/RequestButton';
import SearchBar from './SearchBar';
import { useNavigate } from 'react-router-dom';

const Navbar: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isSticky, setIsSticky] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const { navbarStyle } = useNavbarStyle();
  const [searchAnchorEl, setSearchAnchorEl] = useState<HTMLElement | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchCategories());

    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    const navbarTimeout = setTimeout(() => {
      setShowNavbar(true);
    }, 500);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(navbarTimeout);
    };
  }, [dispatch]);

  const toggleDrawer = (open: boolean) => {
    setDrawerOpen(open);
  };

  const isLightStyle = navbarStyle === 'light';
  const textColor = isLightStyle ? 'black' : 'white';

  const handleSearchEnter = (event: React.MouseEvent<HTMLElement>) => {
    setSearchAnchorEl(event.currentTarget);
  };

  const handleSearchLeave = (event?: React.MouseEvent<HTMLElement>) => {
    const relatedTarget = event?.relatedTarget as HTMLElement;
    if (!relatedTarget || !relatedTarget.closest('.MuiPopper-root')) {
      setSearchAnchorEl(null);
    }
  };

  return (
    <>
      <Slide in={showNavbar} direction="down" timeout={{ enter: 500 }}>
        <AppBar
          position="sticky"
          sx={{
            top: 0,
            transition: 'box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, transform 0.3s ease-in-out',
            boxShadow: isSticky ? 6 : 0,
            transform: isSticky ? 'translateY(0)' : 'translateY(-10px)',
            backgroundColor: 'rgba(255, 255, 255, 0)',
            color: isSticky ? 'black' : textColor,
            margin: 0,
            padding: 0,
            width: "100%",
            display: 'flex',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backdropFilter: isSticky ? 'blur(10px)' : 'none',
              backgroundColor: isSticky ? 'rgba(255, 255, 255, 0.8)' : 'rgba(255, 255, 255, 0)',
              transition: 'backdrop-filter 0.3s ease-in-out, background-color 0.3s ease-in-out',
              zIndex: -1,
            },
          }}
        >
          <Container maxWidth="xl" sx={{
            margin: 0, 
            width: "100%", 
            p: 0,
          }}>
            <Toolbar
              sx={{
                justifyContent: { xs: 'space-between', lg: 'flex-start' },
                alignItems: 'center',
                display: 'flex',
                gap: { xs: '8px', lg: '12px' },
                position: 'relative',
                width: { xs: '100%', lg: "98vw" },
                minHeight: '80px',
                flexWrap: 'nowrap',
              }}
            >
              {/* Мобильное меню */}
              <Box sx={{ 
                display: { xs: 'flex', lg: 'none' },
                alignItems: 'center',
                width: '48px',
              }}>
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => toggleDrawer(true)}
                  sx={{ flexShrink: 0 }}
                >
                  <MenuIcon />
                </IconButton>
              </Box>

              {/* Лого */}
              <Box sx={{ 
                flexShrink: 0, 
                width: 'auto',
              }}>
                <Logo isSticky={isSticky} textColor={textColor} />
              </Box>

              <Box sx={{
                flexGrow: 1, 
                flexShrink: 1, 
                minWidth: 0,
                display: { xs: 'none', lg: 'block' }
              }}>
                <NavbarLinks isSticky={isSticky} />
              </Box>

              <Box sx={{
                flexShrink: 0, 
                width: 'auto',
                display: { xs: 'none', lg: 'block' }
              }}>
                <ContactInfo isSticky={isSticky} textColor={textColor} />
              </Box>

              <Box sx={{
                display: 'flex', 
                gap: 2, 
                alignItems: 'center',
                flexShrink: 0,
                width: 'auto'
              }}>
                <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                  <RequestButton
                    variant="text"
                    filled={false}
                    label="Оставить заявку"
                    dark={isSticky ? isSticky : isLightStyle}
                  />
                </Box>
                
                <Box 
                  onMouseEnter={handleSearchEnter}
                  onMouseLeave={handleSearchLeave}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <IconButton 
                    edge="end" 
                    color="inherit" 
                    aria-label="search" 
                    size="large"
                    onClick={() => navigate('/search')}
                  >
                    <SearchIcon />
                  </IconButton>
                </Box>

                {/*<Box sx={{*/}
                {/*  display: { xs: 'flex', lg: 'none' },*/}
                {/*  alignItems: 'center',*/}
                {/*  width: '48px',*/}
                {/*  justifyContent: 'flex-end',*/}
                {/*}}>*/}
                {/*  <IconButton */}
                {/*    edge="end" */}
                {/*    color="inherit" */}
                {/*    aria-label="search" */}
                {/*    size="large"*/}
                {/*    sx={{ flexShrink: 0 }}*/}
                {/*  >*/}
                {/*    <SearchIcon />*/}
                {/*  </IconButton>*/}
                {/*</Box>*/}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </Slide>

      <NavBarMobile 
        drawerOpen={drawerOpen} 
        toggleDrawer={toggleDrawer}
      />

      <SearchBar
        anchorEl={searchAnchorEl}
        onClose={handleSearchLeave}
        isOpen={Boolean(searchAnchorEl)}
      />
    </>
  );
};

export default Navbar;
