import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem, useTheme, keyframes,
} from '@mui/material';
import api from '../../services/api';

interface RequestModalProps {
  open: boolean;
  onClose: () => void;
}

const RequestModal: React.FC<RequestModalProps> = ({ open, onClose }) => {
  const theme = useTheme();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    other_contacts: '',
    preferred_contact: '',
    message: '',
  });
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error',
  });

  const pulseText = keyframes`
        0% {
            box-shadow: 0 0 0 0 rgba(211, 47, 47, 0.4);
        }
        70% {
            box-shadow: 0 0 0 15px rgba(211, 47, 47, 0);
        }
        100% {
            box-shadow: 0 0 0 0 rgba(211, 47, 47, 0);
        }
    `;

  const hoverAnimationText = keyframes`
        0% {
            transform: scale(1);
            box-shadow: 0 0 0 0 rgba(211, 47, 47, 0.4);
        }
        100% {
            transform: scale(1.05);
            box-shadow: 0 0 0 10px rgba(211, 47, 47, 0);
        }
    `;

  const handleChange = (e: { target: { name: any; value: any; }; }) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const handleSubmit = async () => {
    const newErrors: Record<string, string> = {};

    if (!formData.name) newErrors.name = 'Имя обязательно';
    if (!formData.email) newErrors.email = 'Email обязателен';
    if (!formData.message) newErrors.message = 'Сообщение обязательно';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      await api.post('/api/requests/', formData);
      setSnackbar({
        open: true,
        message: 'Заявка успешно отправлена!',
        severity: 'success',
      });
      onClose();
      setFormData({
        name: '',
        email: '',
        phone: '',
        other_contacts: '',
        preferred_contact: '',
        message: '',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Ошибка при отправке заявки',
        severity: 'error',
      });
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Отправить заявку</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              required
              label="Имя"
              name="name"
              value={formData.name}
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors.name}
              fullWidth
            />
            <TextField
              required
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
              fullWidth
            />
            <TextField
              label="Телефон"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="Другие контакты"
              name="other_contacts"
              value={formData.other_contacts}
              onChange={handleChange}
              fullWidth
              multiline
              rows={2}
            />
            <FormControl fullWidth>
              <InputLabel>Предпочтительный способ связи</InputLabel>
              <Select
                name="preferred_contact"
                value={formData.preferred_contact}
                onChange={handleChange}
                label="Предпочтительный способ связи"
              >
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="phone">Телефон</MenuItem>
                <MenuItem value="whatsapp">WhatsApp</MenuItem>
              </Select>
            </FormControl>
            <TextField
              required
              label="Сообщение"
              name="message"
              value={formData.message}
              onChange={handleChange}
              error={!!errors.message}
              helperText={errors.message}
              fullWidth
              multiline
              rows={4}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            sx={{
              color: theme.palette.error.main,
            }}
          >Отмена</Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{
              backgroundColor: theme.palette.error.main,
              '&:hover': {
                animation: `${hoverAnimationText} 0.5s ease-in-out`,
                transform: 'scale(1.05)',
              },
              animation: `${pulseText} 2s infinite`,
              borderRadius: '10px',
            }}
          >
            Отправить
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity} onClose={() => setSnackbar({ ...snackbar, open: false })}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RequestModal; 