import React from 'react';
import { Container } from '@mui/material';
import LicensesSection from './LicensesSection';
import CompanyDescription from './CompanyDescription';

const Company: React.FC = () => {
  return (
    <Container maxWidth="lg" sx={{ paddingY: 5 }}>
      <CompanyDescription />
      <LicensesSection />
    </Container>
  );
};

export default Company;
