import React from 'react';
import { Typography, List, ListItem, ListItemText, Box } from '@mui/material';
import parse, {
  domToReact,
  DOMNode as HtmlDomNode,
  Element as HtmlElement,
} from 'html-react-parser';
import HoverLink from '../HoverLink';


interface ProductListBlockProps {
  title?: string;
  items: string[];
}

function isElement(node: HtmlDomNode): node is HtmlElement {
  return node.type === 'tag';
}

const ListBlock: React.FC<ProductListBlockProps> = ({ title, items }) => {
  return (
    <Box>
      {title && (
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
      )}
      <List sx={{ listStyleType: 'disc', pl: 4 }}>
        {items.map((item, i) => (
          <ListItem
            key={i}
            sx={{
              display: 'list-item',
              listStyleType: 'disc',
              pl: 0,
              py: 0.5,
            }}
          >
            <ListItemText>
              {parse(item, {
                replace: (domNode: HtmlDomNode) => {
                  if (isElement(domNode) && domNode.name === 'hoverlink') {
                    const innerContent = domToReact(domNode.children as HtmlDomNode[]);
                    const { href, download } = domNode.attribs || {};

                    return (
                      <HoverLink to={href} download={download} variant="body1">
                        {innerContent}
                      </HoverLink>
                    );
                  }
                },
              })}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default ListBlock;
