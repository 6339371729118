import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

interface LogoProps {
  isSticky: boolean;
  textColor: string;
}

const Logo: React.FC<LogoProps> = ({ isSticky, textColor }) => {
  const location = useLocation();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (location.pathname === '/') {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const images = [
      'https://s3.timeweb.cloud/b510c84d-test/logos/logo_no_bg.png',
      'https://s3.timeweb.cloud/b510c84d-test/logos/logo_no_bg_white.png',
    ];
    images.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: 80,
        transition: 'margin 0.3s ease-in-out',
      }}
    >
      <Link
        to="/"
        onClick={handleClick}
        style={{
          display: 'flex',
          alignItems: 'center',
          height: 80,
          position: 'relative',
        }}
      >
        <img
          src="https://s3.timeweb.cloud/b510c84d-test/logos/logo_no_bg_white.png"
          alt="Логотип"
          style={{
            height: '95%',
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: isSticky ? 0 : (textColor === 'black' ? 0 : 1),
            transition: 'opacity 0.3s ease-in-out',
          }}
        />
        <img
          src="https://s3.timeweb.cloud/b510c84d-test/logos/logo_no_bg.png"
          alt="Логотип"
          style={{
            height: '95%',
            opacity: isSticky ? 1 : (textColor === 'black' ? 1 : 0),
            transition: 'opacity 0.3s ease-in-out',
          }}
        />
      </Link>
    </Box>
  );
};

export default Logo;
