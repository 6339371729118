import React, { useState } from 'react';
import { Box, Typography, Menu, MenuItem, ListItemText, ListItem, Link } from '@mui/material';

interface ContactInfoProps {
  isSticky: boolean;
  textColor: string;
}

const ContactInfo: React.FC<ContactInfoProps> = ({ isSticky, textColor }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const phoneNumbers = [
    {
      number: '+7 910 836-49-95',
      href: 'tel:+79108364995',
      description: 'Телефон представителя в РФ',
    },
    {
      number: '+86 155-021-063-10',
      href: 'tel:+8615502106310',
      description: 'Телефон в Шанхае',
    },
    {
      number: '+7 910 935-11-52',
      href: 'tel:+79109351152',
      description: 'Телефон представителя в РФ',
    },
  ];

  return (
    <Box
      sx={{
        marginLeft: {
          xs: 0,
          lg: 'auto',
        },
        display: { xs: 'none', lg: 'flex' },
        alignItems: 'center',
        justifyContent: 'center',
        height: 64,
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: 0,
          height: '2px',
          backgroundColor: isSticky ? 'black' : 'white',
          transition: 'width 0.3s ease-in-out',
        },
        '&:hover::after': {
          width: '100%',
        },
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Typography
        variant="h6"
        sx={{
          color: isSticky ? 'black' : textColor === 'black' ? 'black' : 'white',
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: -2,
            left: 0,
            width: 0,
            height: '2px',
            backgroundColor: isSticky ? 'black' : 'white',
            transition: 'width 0.3s ease-in-out',
          },
          '&:hover::after': {
            width: '100%',
          },
        }}
      >
        <a href={phoneNumbers[0].href} style={{ textDecoration: 'none', color: 'inherit' }}>
          {phoneNumbers[0].number}
        </a>
      </Typography>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMouseLeave}
        disableScrollLock={true}
        PaperProps={{
          onMouseLeave: handleMouseLeave,
          sx: {
            padding: '10px 15px',
            borderRadius: '10px',
            boxShadow: 'none',
            backgroundColor: isSticky ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.8)',
            ml: '14px',
            mt: isSticky ? '-10px' : '-10px',
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {phoneNumbers.map((phone, index) => (
          <MenuItem
            key={index}
            disableRipple
            disableGutters
            sx={{
              padding: 0,
              '&:hover': { backgroundColor: 'transparent' },
              '&.Mui-focusVisible': { backgroundColor: 'transparent' },
            }}
          >
            <ListItem
              component={Link}
              href={phone.href}
              sx={{
                padding: 0,
                color: 'black',
                position: 'relative',
              }}
            >
              <ListItemText
                primary={
                  <>
                    <Typography
                      variant="h6"
                      sx={{
                        color: 'black',
                        fontWeight: 600,
                        display: 'inline',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      {phone.number}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="black"
                      sx={{ display: 'block', marginTop: '0', marginBottom: '5px' }}
                    >
                      {phone.description}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default ContactInfo;
