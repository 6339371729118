import React from 'react';
import ContentBlock from '../DataPresentation/ContentBlock';
import { Container, Card, CardContent, Box } from '@mui/material';
import { IContentBlock, ITitle } from '../../store/slices/productSlice';
import { ServicesData } from './ServicesData';
import Title from '../DataPresentation/Title';
import AnimatedCard from '../AnimatedCard';

const titleToId = (text: string): string => {
  return text
    .toLowerCase()
    .replace(/[^а-яёa-z0-9\s]/g, '')
    .replace(/\s+/g, '-');
};

const Services: React.FC = () => {
  return (
    <Container sx={{ mt: 4 }}>
      <AnimatedCard>
        <Title name="Услуги" />
      </AnimatedCard>

      {ServicesData.map((block: IContentBlock, index: number) => {
        if (block.type === 'title') {
          const titleBlock = block as ITitle;
          const titleId = titleToId(titleBlock.text);
          
          return (
            <Box id={titleId} key={index}>
              <AnimatedCard>
                <Title
                  name={titleBlock.text}
                  level={titleBlock.level}
                />
              </AnimatedCard>
            </Box>
          );
        } else {
          return (
            <AnimatedCard key={index}>
              <Card sx={{ mb: 2, borderRadius: 2, boxShadow: 3, padding: 2 }}>
                <CardContent>
                  <ContentBlock block={block} />
                </CardContent>
              </Card>
            </AnimatedCard>
          );
        }
      })}
    </Container>
  );
};

export default Services;
