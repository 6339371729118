import React, { useState } from 'react';
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Box, 
  Grid, 
  Divider
} from '@mui/material';
import { Link } from 'react-router-dom';
import { IProduct } from '../../store/slices/productSlice';
import HoverLink from '../HoverLink';

interface ProductCardProps {
  product: IProduct;
}

const ProductCardMini: React.FC<ProductCardProps> = ({ product }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const productImage = product.image ? product.image : '/images/placeholder.jpg';

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = '/images/placeholder.jpg';
  };

  return (
    <Box
      sx={{
        perspective: '1000px',
        width: '100%',
        display: 'flex',
      }}
      onMouseEnter={() => setIsFlipped(true)}
      onMouseLeave={() => setIsFlipped(false)}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          transition: 'transform 0.8s',
          transformStyle: 'preserve-3d',
          transform: isFlipped ? 'rotateY(180deg)' : 'rotateY(0)',
        }}
      >
        {/* Передняя сторона */}
        <Card
          sx={{
            position: 'relative',
            width: '100%',
            backfaceVisibility: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            boxShadow: 3,
            borderRadius: '16px',
            backgroundColor: '#ffffff',
          }}
        >
          <Box sx={{ position: 'relative', width: '100%', paddingTop: '100%' }}>
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundImage: `url(${productImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                filter: 'blur(10px)',
                transform: 'scale(1.1)',
              }}
            />
            <CardMedia
              component="img"
              image={productImage}
              alt={product.name}
              onError={handleImageError}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                maxHeight: '100%',
                maxWidth: '100%',
                transform: 'translate(-50%, -50%)',
                objectFit: 'contain',
              }}
            />
          </Box>

          <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
            <HoverLink
              to={`/products/${product.id}`}
              label={product.name}
              color="inherit"
              variant="h5"
              sx={{ my: 1 }}
            />
          </CardContent>
        </Card>

        {/* Задняя сторона */}
        <Card
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backfaceVisibility: 'hidden',
            transform: 'rotateY(180deg)',
            display: 'flex',
            flexDirection: 'column',
            boxShadow: 3,
            borderRadius: '16px',
            backgroundColor: '#ffffff',
          }}
        >
          <CardContent sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            height: '100%',
            position: 'relative',
            padding: 2
          }}>
            <Typography variant="h5" gutterBottom>
              {product.name}
            </Typography>
            
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              gap: 2
            }}>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxHeight: 'calc(100% - 120px)', // Оставляем место для кнопки и характеристик
                }}
              >
                {product.description}
              </Typography>

              <Divider />

              {product.card_specs && (
                <Box sx={{ flexShrink: 0 }}>
                  <Grid container spacing={1}>
                    {Object.entries(product.card_specs)
                      .slice(0, 3)
                      .map(([key, value], index) => (
                        <React.Fragment key={key}>
                          <Grid item xs={6}>
                            <Typography variant="body2" color="text.secondary">
                              {key}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2" color="text.secondary">
                              {value}
                            </Typography>
                          </Grid>
                        </React.Fragment>
                      ))}
                  </Grid>
                </Box>
              )}

              <Button
                variant="contained"
                fullWidth
                component={Link}
                to={`/products/${product.id}`}
                sx={{
                  mt: 'auto',
                  backgroundColor: 'rgba(240,240,240)',
                  backdropFilter: 'blur(8px)',
                  color: 'black',
                  '&:hover': {
                    backgroundColor: 'rgba(200, 200, 200)',
                  },
                }}
              >
                Подробнее
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default ProductCardMini;
