// src/components/AnimationContext.tsx

import React, { createContext, useContext, useRef, useCallback, ReactNode } from 'react';

export interface AnimationContextProps {
  registerCard: () => number;
  unregisterCard: () => void;
}

const AnimationContext = createContext<AnimationContextProps | undefined>(undefined);

export const useAnimationContext = (): AnimationContextProps => {
  const context = useContext(AnimationContext);
  if (!context) {
    throw new Error('useAnimationContext must be used within an AnimationProvider');
  }
  return context;
};

interface AnimationProviderProps {
  children: ReactNode;
}

export const AnimationProvider: React.FC<AnimationProviderProps> = ({ children }) => {
  const activeCountRef = useRef(0);
  const resetTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const registerCard = useCallback((): number => {
    activeCountRef.current += 1;
    if (resetTimeoutRef.current) {
      clearTimeout(resetTimeoutRef.current);
      resetTimeoutRef.current = null;
    }
    return activeCountRef.current;
  }, []);

  const unregisterCard = useCallback((): void => {
    if (activeCountRef.current > 0) {
      activeCountRef.current -= 1;
      if (activeCountRef.current === 0) {
        resetTimeoutRef.current = setTimeout(() => {
          activeCountRef.current = 0;
        }, 500);
      }
    }
  }, []);

  return (
    <AnimationContext.Provider value={{ registerCard, unregisterCard }}>
      {children}
    </AnimationContext.Provider>
  );
};
