// src/components/Company/CompanyDescription.tsx

import React from 'react';
import { Typography, Box, Paper, Divider } from '@mui/material';
import { Fade } from '@mui/material';

const CompanyDescription: React.FC = () => {
  return (
    <Fade in={true} timeout={1000}>
      <Box sx={{ mb: 4 }}>
        <Paper
          elevation={3}
          sx={{
            padding: 4,
            borderRadius: 8,
            backgroundColor: 'background.paper',
            transition: 'box-shadow 0.3s ease',
            boxShadow: 3,
            '&:hover': {
              boxShadow: 6,
            },
          }}
        >
          <Typography variant="h5" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold' }}>
            {'О компании "Юджен"'}
          </Typography>
          <Divider sx={{ mb: 3 }} />

          <Typography variant="body1" sx={{ mb: 2 }}>
            {'Компания "Юджен" — это молодая и динамично развивающаяся торговая компания, специализирующаяся на поставках товаров из Китая. Мы ориентированы на предоставление нашим клиентам широчайшего ассортимента продукции, включая высококачественные станки, различное оборудование, а также авто и мото технику.'}
          </Typography>

          <Typography variant="body1" sx={{ mb: 2 }}>
            {'С момента своего основания "Юджен" стремится установить надежные связи с китайскими производителями, что позволяет нам предлагать конкурентоспособные цены и гарантировать высокий стандарт качества. Мы внимательно следим за современными трендами и потребностями рынка, что делает наш ассортимент актуальным и востребованным.'}
          </Typography>

          <Typography variant="body1" sx={{ mb: 2 }}>
            Наша команда профессионалов обладает глубокими знаниями в области международной торговли и логистики, что позволяет нам быстро и эффективно организовывать поставки и обеспечивать удовлетворение запросов наших клиентов. Мы гордимся своей репутацией надежного партнера и стремимся к долгосрочным отношениям с каждым клиентом.
          </Typography>

          <Typography variant="body1" sx={{ mb: 2 }}>
            Мы готовы предложить индивидуальные решения для бизнеса любой сложности и обеспечить полный спектр услуг, начиная от консультирования по выбору товаров и заканчивая доставкой прямо в руки клиента.
          </Typography>

          <Typography variant="body1" fontWeight="bold" sx={{ textAlign: 'center' }}>
            {'"Юджен" — ваш надежный партнер в мире торговли и технологий!'}
          </Typography>
        </Paper>
      </Box>
    </Fade>
  );
};

export default CompanyDescription;
