import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import HomePage from './pages/HomePage';
import CatalogPage from './pages/CatalogPage';
import ContactsPage from './pages/ContactsPage';
import CompanyPage from './pages/CompanyPage';
import ScrollToTop from './components/ScrollToTop';
import HowToBuyPage from './pages/HowToBuyPage';
import ProductDetailsPage from './pages/ProductDetailsPage';
import ErrorPage from './pages/ErrorPage';
import ServicesPage from './pages/ServicesPage';
import BackToTopButton from './components/BackToTopButton';
import RequestSystem from './components/RequestSystem/RequestSystem';
import { RequestModalProvider } from './contexts/RequestModalContext';
import SearchPage from './pages/SearchPage';

const App: React.FC = () => {
  return (
    <Router>
      <RequestModalProvider>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/catalog/:categoryId" element={<CatalogPage />} />
          <Route path="/catalog" element={<CatalogPage />} />
          <Route path="/contacts" element={<ContactsPage />} />
          <Route path="/company" element={<CompanyPage />} />
          <Route path="/how-to-buy" element={<HowToBuyPage />} />
          <Route path="/products/:productId" element={<ProductDetailsPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <Footer />
        <BackToTopButton />
        <RequestSystem />
      </RequestModalProvider>
    </Router>
  );
};

export default App;
