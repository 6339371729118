// src/components/AnimatedCard.tsx

import React, { useEffect, useRef, useState, ReactNode } from 'react';
import { Box, Fade, SxProps, Theme } from '@mui/material';
import { useAnimationContext } from './AnimationContext';

interface AnimatedCardProps {
  children: ReactNode;
  animationDuration?: number;
  initialDelay?: number;
  stepDelay?: number;
  sx?: SxProps<Theme>;
  height?: number | string;
  maxHeight?: number;
}

const AnimatedCard: React.FC<AnimatedCardProps> = ({
                                                     children,
                                                     animationDuration = 800,
                                                     initialDelay = 300,
                                                     stepDelay = 100,
                                                     sx = {},
                                                     height,
                                                     maxHeight,
                                                   }) => {
  const [visible, setVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { registerCard, unregisterCard } = useAnimationContext();

  useEffect(() => {
    if (!ref.current) return;

    const observerOptions = {
      threshold: 0.1,
    };

    const observerCallback: IntersectionObserverCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !visible) {
          const cardIndex = registerCard();
          const calculatedDelay = initialDelay + cardIndex * stepDelay;

          setTimeout(() => {
            setVisible(true);
            setTimeout(() => {
              unregisterCard();
            }, animationDuration);
          }, calculatedDelay);

          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    observer.observe(ref.current);

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [registerCard, unregisterCard, visible, animationDuration, initialDelay, stepDelay]);

  return (
    <div
      ref={ref}
      style={{
        width: '100%',
        display: 'flex',
        height: height,
      }}
    >
      <div
        style={{
          transform: visible ? 'translateY(0)' : 'translateY(100px)',
          transition: `transform ${animationDuration}ms`,
          width: '100%',
          display: 'flex',
          height: height,
        }}
      >
        <Fade in={visible} timeout={animationDuration}>
          <Box
            sx={{
              width: '100%',
              height: height,
              maxHeight: maxHeight,
              ...sx,
            }}
          >
            {children}
          </Box>
        </Fade>
      </div>
    </div>
  );
};

export default AnimatedCard;
