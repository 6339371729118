import React from 'react';
import { Box, Container, Typography, Button, Zoom } from '@mui/material';
import { Parallax } from 'react-scroll-parallax';
import { Category } from '../../store/slices/catalogSlice';
import HoverLink from '../HoverLink';
import RequestButton from '../RequestButton/RequestButton';

interface CatalogHeaderProps {
  categoryPath: Category[] | null;
  selectedCategory: Category | null;
  scrollToContent: () => void;
}

const CatalogHeader: React.FC<CatalogHeaderProps> = ({ categoryPath, selectedCategory, scrollToContent }) => {
  return (
    <>
      <Box
        component="div"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh',
          zIndex: -1,
          overflow: 'hidden',
          '::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1,
          },
        }}
      >
        <Parallax speed={-50} style={{ minHeight: '100vh', width: '100%' }}>
          {selectedCategory && selectedCategory.image ? (
            <Box
              sx={{
                backgroundImage: `url(${selectedCategory.image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '100%',
                height: '100vh',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: -1,
              }}
            />
          ) : (
            <video
              autoPlay
              muted
              loop
              style={{
                width: '100%',
                height: '100vh',
                objectFit: 'cover',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: -1,
              }}
            >
              <source src="https://s3.timeweb.cloud/b510c84d-test/videos/laser_cnc.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </Parallax>
      </Box>

      <Container maxWidth="xl" sx={{ paddingBottom: '2vh', position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            top: '20px',
            left: '20px',
            zIndex: 2,
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            color: '#fff',
          }}
        >
          <HoverLink
            to="/catalog"
            label="Каталог"
            color="inherit"
            underlineColor="white"
            variant="body1"
            sx={{
              fontWeight: '500',
              fontSize: { xs: '12px', sm: '14px', md: '18px' }
            }}
          />
          {categoryPath && categoryPath.map((category, index) => {
            return (
              <React.Fragment key={category.id}>
                <Typography
                  variant="body1"
                  sx={{ margin: '0 8px', color: '#fff' }}
                >
                  /
                </Typography>
                <HoverLink
                  to={`/catalog/${category.id}`}
                  label={category.name}
                  color="inherit"
                  underlineColor="white"
                  variant="body1"
                  sx={{
                    fontWeight: '500',
                    fontSize: { xs: '12px', sm: '14px', md: '18px' }
                  }}
                />
              </React.Fragment>
            );
          })}
        </Box>

        <Box p={2}>
          <Container
            sx={{
              paddingTop: '20vh',
              width: { xs: 'auto', sm: 500, md: 700 },
              height: '90vh',
              borderRadius: 4,
              zIndex: 2,
              color: '#fff',
              textAlign: 'center',
              position: 'relative',
            }}
          >
            <Typography
              gutterBottom
              sx={{ fontWeight: '800', fontSize: { xs: '5vw', sm: '36px', md: '42px' } }}
            >
              {selectedCategory ? selectedCategory.name : 'Наш каталог'}
            </Typography>
            <Typography
              variant="h5"
              sx={{ textAlign: 'center', fontSize: { xs: '4vw', sm: '18px', md: '24px' } }}
            >
              {selectedCategory && selectedCategory.description
                ? selectedCategory.description
                : selectedCategory
                  ? `Откройте для себя нашу подборку в категории ${selectedCategory.name}`
                  : ''}
            </Typography>
            <Zoom in={true} timeout={1000} style={{ transitionDelay: '1000ms' }}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                alignItems: 'center',
                position: 'absolute',
                ml: 'auto',
                mr: 'auto',
                left: '0',
                right: '0',
                bottom: '5%'
              }}>
                <Button
                  variant="contained"
                  onClick={scrollToContent}
                  sx={{
                    color: 'black',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    borderRadius: '10px',
                    backdropFilter: 'blur(5px)',
                    padding: '10px 20px',
                    fontSize: '16px',
                    transition: 'background-color 0.3s ease',
                    animation: 'breathAnimation 1s ease-in-out infinite',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.6)',
                    },
                  }}
                >
                  Каталог
                </Button>

                <RequestButton
                  variant="text"
                  filled={false}
                  label="Оставить заявку"
                  sx={{mt: '10px'}}
                />
              </Box>
            </Zoom>
          </Container>
        </Box>
      </Container>
    </>
  );
};

export default CatalogHeader;
