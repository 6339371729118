import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ParallaxProvider } from 'react-scroll-parallax';
import './index.css';
import { AnimationProvider } from './components/AnimationContext';
import { NavbarStyleProvider } from './components/NavbarStyleContext';

const theme = createTheme({
  typography: {
    fontFamily: 'Inter, sans-serif',
  },
});

async function prepare() {
  return
}

prepare().then(() => {
  const root = ReactDOM.createRoot(document.getElementById('root')!);
  root.render(
    <Provider store={store}>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <ParallaxProvider>
            <AnimationProvider>
              <NavbarStyleProvider>
                <CssBaseline />
                <App />
              </NavbarStyleProvider>
            </AnimationProvider>
          </ParallaxProvider>
        </ThemeProvider>
      </React.StrictMode>
    </Provider>
  );
});
