import React, { useEffect } from 'react';
import ProductPage from '../components/Product/ProductPage';
import { useNavbarStyle } from '../components/NavbarStyleContext';
import { useNavigate, useParams } from 'react-router-dom';

const ProductDetailsPage: React.FC = () => {
  const { productId } = useParams<{ productId: string }>();
  const { setNavbarStyle } = useNavbarStyle();
  const navigate = useNavigate();

  useEffect(() => {
    setNavbarStyle('dark');

    return () => {
      setNavbarStyle('dark');
    };
  }, [setNavbarStyle]);

  useEffect(() => {
    if (!productId) {
      navigate('/error');
    }
  }, [productId, navigate]);

  if (!productId) {
    return null;
  }

  return <ProductPage productId={productId} />;
};

export default ProductDetailsPage;
