import React, { useEffect, useState } from 'react';
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  Grid,
  Grow,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { Category } from '../../store/slices/catalogSlice';

interface CatalogMenuProps {
  anchorEl: HTMLElement | null;
  isOpen: boolean;
  onClose: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const CatalogMenu: React.FC<CatalogMenuProps> = ({
                                                   anchorEl,
                                                   isOpen,
                                                   onClose,
                                                   onMouseEnter,
                                                   onMouseLeave,
                                                 }) => {
  const { categories } = useAppSelector((state) => state.catalog);
  const [activeCategory, setActiveCategory] = useState<string | null>(null);

  useEffect(() => {
    if (categories.length > 0 && !activeCategory) {
      setActiveCategory(categories[0].id);
    }
  }, [categories, activeCategory]);

  const handleMouseEnterCategory = (categoryId: string) => {
    setActiveCategory(categoryId);
  };

  const getActiveCategoryChildren = () => {
    const category = categories.find(
      (cat: Category) => cat.id === activeCategory,
    );
    return category?.children || [];
  };

  const getMenuPosition = () => {
    if (anchorEl) {
      const rect = anchorEl.getBoundingClientRect();
      const parentRect = anchorEl.offsetParent?.getBoundingClientRect();
      return {
        top: parentRect ? rect.bottom - parentRect.top : rect.bottom,
        left: parentRect ? rect.left - parentRect.left : rect.left,
      };
    }
    return { top: 0, left: 0 };
  };

  const companyRedColor = '#D32F2F';

  return (
    <Grow in={isOpen} timeout={300} style={{ transformOrigin: '0 0 0' }}>
      <Box
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        sx={{
          position: 'absolute',
          top: getMenuPosition().top,
          left: getMenuPosition().left,
          width: { lg: '60vw' },
          maxHeight: 500,
          display: { xs: 'none', lg: 'flex' },
          backgroundColor: 'transparent',
          borderRadius: '10px',
          overflow: 'hidden',
          zIndex: 1300,
          color: 'black',
          '&::before': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: -1,
          },
        }}
      >
        <Box
          sx={{
            flex: 1,
            borderRight: '1px solid #ddd',
            overflowY: 'auto',
          }}
        >
          <List>
            {categories.map((category: Category) => (
              <ListItemButton
                key={category.id}
                onMouseEnter={() => handleMouseEnterCategory(category.id)}
                component={Link}
                to={`/catalog/${category.id}`}
                onClick={onClose}
                sx={{
                  backgroundColor:
                    category.id === activeCategory ? 'rgba(255, 255, 255, 0.4)' : 'transparent',
                  color: 'inherit',
                  textDecoration: 'none',
                  transition: 'color 0.3s ease',
                  padding: '8px 16px',
                  '&:hover': {
                    color: companyRedColor,
                    backgroundColor: 'rgba(255, 255, 255, 0.4)',
                  },
                }}
              >
                <ListItemText
                  primary={category.name}
                  primaryTypographyProps={{
                    fontWeight: 'bold',
                    sx: { 
                      color: 'inherit',
                      fontSize: { lg: '0.9rem', xl: '1rem' },
                      wordWrap: 'break-word',
                      whiteSpace: 'normal',
                      lineHeight: 1.2,
                    },
                  }}
                />
              </ListItemButton>
            ))}
          </List>
        </Box>

        <Box
          sx={{
            flex: 3,
            padding: 2,
            overflowY: 'auto',
          }}
        >
          {getActiveCategoryChildren().length > 0 ? (
            <Grid container spacing={2}>
              {getActiveCategoryChildren().map((subcat: Category) => (
                <Grid item xs={12} sm={6} md={4} key={subcat.id}>
                  <ListItemButton
                    component={Link}
                    to={`/catalog/${subcat.id}`}
                    onClick={onClose}
                    sx={{
                      padding: '4px 8px',
                      color: 'inherit',
                      textDecoration: 'none',
                      transition: 'color 0.3s ease',
                      '&:hover': {
                        color: companyRedColor,
                        backgroundColor: 'transparent',
                      },
                    }}
                  >
                    <ListItemText
                      primary={subcat.name}
                      primaryTypographyProps={{
                        variant: 'h5',
                        sx: {
                          textDecoration: 'none',
                          color: 'inherit',
                          fontWeight: 'bold',
                          fontSize: { lg: '1.1rem', xl: '1.25rem' },
                          wordWrap: 'break-word',
                          whiteSpace: 'normal',
                          lineHeight: 1.2,
                        },
                      }}
                    />
                  </ListItemButton>

                  {subcat.children && subcat.children.length > 0 && (
                    <List dense>
                      {subcat.children.map((subSubcat: Category) => (
                        <ListItemButton
                          key={subSubcat.id}
                          component={Link}
                          to={`/catalog/${subSubcat.id}`}
                          onClick={onClose}
                          disableGutters
                          sx={{
                            paddingLeft: 1,
                            color: 'inherit',
                            textDecoration: 'none',
                            transition: 'color 0.3s ease',
                            '&:hover': {
                              color: companyRedColor,
                              backgroundColor: 'transparent',
                            },
                          }}
                        >
                          <ListItemText
                            primary={subSubcat.name}
                            primaryTypographyProps={{
                              variant: 'body1',
                              sx: {
                                textDecoration: 'none',
                                color: 'inherit',
                              },
                            }}
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  )}
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant="body2" color="textSecondary">
              Выберите категорию слева, чтобы увидеть подкатегории
            </Typography>
          )}
        </Box>
      </Box>
    </Grow>
  );
};

export default CatalogMenu;
