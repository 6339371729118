import React, { createContext, useContext, useState, ReactNode } from 'react';
import RequestModal from '../components/RequestModal/RequestModal';

interface RequestModalContextType {
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

const RequestModalContext = createContext<RequestModalContextType | undefined>(undefined);

interface RequestModalProviderProps {
  children: ReactNode;
}

export const RequestModalProvider: React.FC<RequestModalProviderProps> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <RequestModalContext.Provider value={{ isModalOpen, openModal, closeModal }}>
      {children}
      <RequestModal open={isModalOpen} onClose={closeModal} />
    </RequestModalContext.Provider>
  );
};

export const useRequestModal = () => {
  const context = useContext(RequestModalContext);
  if (context === undefined) {
    throw new Error('useRequestModal must be used within a RequestModalProvider');
  }
  return context;
};

export default RequestModalContext; 