import React, { useEffect } from 'react';
import {
  Typography,
  Box,
  Container,
  CircularProgress,
  TextField,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { searchCatalog, clearSearch } from '../store/slices/searchSlice';
import CategoryList from '../components/Catalog/CategoryList';
import ProductList from '../components/Catalog/ProductList';
import debounce from 'lodash/debounce';
import { useNavbarStyle } from '../components/NavbarStyleContext';
import LoadingAnimation from '../components/LoadingAnimation';
import { useSearchParams } from 'react-router-dom';

const SearchPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = React.useState(searchParams.get('q') || '');
  const dispatch = useAppDispatch();
  const { results, loading } = useAppSelector(state => state.search);
  const { setNavbarStyle } = useNavbarStyle();

  useEffect(() => {
    setNavbarStyle('light'); 

    return () => {
      setNavbarStyle('dark');
    };
  }, [setNavbarStyle]);

  const debouncedSearch = React.useCallback(
    debounce((query: string) => {
      if (query.length >= 3) {
        dispatch(searchCatalog(query));
      }
    }, 300),
    [dispatch]
  );

  useEffect(() => {
    return () => {
      dispatch(clearSearch());
    };
  }, [dispatch]);

  useEffect(() => {
    if (searchQuery.length >= 3) {
      dispatch(searchCatalog(searchQuery));
    }
  }, []);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ pt: 12, pb: 4 }}>
        <Typography variant="h4" mb={4}>
          Поиск по каталогу
        </Typography>
        
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Введите запрос для поиска..."
          value={searchQuery}
          onChange={handleSearch}
          sx={{ mb: 4 }}
        />

        {loading ? (
          <LoadingAnimation message="Поиск..." />
        ) : (
          searchQuery.length >= 3 && (
            <Box>

              {results.products.length > 0 && (
                <Box mb={4}>
                  <Typography variant="h5" mb={2}>
                    Товары
                  </Typography>
                  <ProductList products={results.products} />
                </Box>
              )}

              {results.categories.length > 0 && (
                <Box mb={4}>
                  <Typography variant="h5" mb={2}>
                    Категории
                  </Typography>
                  <CategoryList categories={results.categories} />
                </Box>
              )}

              {results.categories.length === 0 && results.products.length === 0 && (
                <Typography color="textSecondary" align="center">
                  Ничего не найдено
                </Typography>
              )}
            </Box>
          )
        )}
      </Box>
    </Container>
  );
};

export default SearchPage; 