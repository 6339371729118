// src/components/Contacts/RepresentativeSection.tsx

import React, { useState } from 'react';
import { Typography, Paper, List, ListItem, ListItemText, Box, Divider, IconButton, Tooltip, Snackbar, Alert } from '@mui/material';
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import { Fade } from '@mui/material';
import HoverLink from '../HoverLink';

const RepresentativeSection: React.FC = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [copiedText, setCopiedText] = useState<string>('');
  const [snackbarKey, setSnackbarKey] = useState(0); // Добавляем ключ для Snackbar

  const copyText = `
    Официальный торговый представитель компании «Юджен» в России:
    ИП Самсонов Сергей Николаевич
    ИНН: 691606094443
    ОГРНИП: 317695200025290
    ЭДО: yuzhen.shop
  `;

  // Закрытие и открытие Snackbar с новым текстом
  const handleCopyAll = () => {
    setCopiedText('реквезиты представителя');
    setOpenSnackbar(false);
    setSnackbarKey((prevKey) => prevKey + 1); // Меняем ключ для Snackbar
    setTimeout(() => {
      navigator.clipboard.writeText(copyText.trim());
      setOpenSnackbar(true);
    }, 100);
  };

  const handleCopy = (text: string) => {
    setCopiedText(text);
    setOpenSnackbar(false);
    setSnackbarKey((prevKey) => prevKey + 1); // Меняем ключ для Snackbar
    setTimeout(() => {
      navigator.clipboard.writeText(text);
      setOpenSnackbar(true);
    }, 100);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false); // Закрываем Snackbar
  };

  return (
    <>
      <Fade in={true} timeout={1000}>
        <Paper
          elevation={3}
          sx={{
            padding: 4,
            marginBottom: 4,
            borderRadius: 8,
            transition: 'box-shadow 0.5s ease',
            boxShadow: 3,
            '&:hover': {
              boxShadow: 6,
            },
            position: 'relative',
          }}
        >
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: 3 }}>
            Реквизиты представителя
            <Tooltip title="Скопировать все реквизиты" arrow>
              <IconButton
                onClick={handleCopyAll}
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  color: 'inherit',
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Typography>

          <Divider sx={{ mb: 2 }} />

          <List>
            <ListItem>
              <ListItemText
                primary="Официальный торговый представитель компании «Юджен» в России:"
                sx={{ fontWeight: 'medium', textAlign: 'center' }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="ИП Самсонов Сергей Николаевич"
                sx={{ textAlign: 'center' }}
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary={
                  <Box component="span">
                    ИНН:{' '}
                    <Tooltip title="Нажмите, чтобы скопировать ИНН" arrow>
                      <HoverLink
                        to="#"
                        label="691606094443"
                        underlineColor="black"
                        color="inherit"
                        variant="body1"
                        onClick={() => handleCopy('691606094443')}
                      />
                    </Tooltip>
                  </Box>
                }
                sx={{ textAlign: 'center' }}
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary={
                  <Box component="span">
                    ОГРНИП:{' '}
                    <Tooltip title="Нажмите, чтобы скопировать ОГРНИП" arrow>
                      <HoverLink
                        to="#"
                        label="317695200025290"
                        underlineColor="black"
                        color="inherit"
                        variant="body1"
                        onClick={() => handleCopy('317695200025290')}
                      />
                    </Tooltip>
                  </Box>
                }
                sx={{ textAlign: 'center' }}
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary={
                  <Box component="span">
                    ЭДО:{' '}
                    <Tooltip title="Нажмите, чтобы скопировать ЭДО" arrow>
                      <HoverLink
                        to="#"
                        label="yuzhen.shop"
                        underlineColor="black"
                        color="inherit"
                        variant="body1"
                        onClick={() => handleCopy('yuzhen.shop')}
                      />
                    </Tooltip>
                  </Box>
                }
                sx={{ textAlign: 'center' }}
              />
            </ListItem>
          </List>
        </Paper>
      </Fade>

      {/* Snackbar для уведомления */}
      <Snackbar
        key={snackbarKey} // Используем ключ, чтобы Snackbar обновлялся
        open={openSnackbar}
        autoHideDuration={3000} // Длительность отображения (3 сек)
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Положение Snackbar на экране
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Скопировано: {copiedText}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RepresentativeSection;
