import React from 'react';
import parse, {
  domToReact,
  DOMNode as HtmlDomNode,
  Element as HtmlElement,
} from 'html-react-parser';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from '@mui/material';
import { ITableBlock, ITableCellData } from '../../store/slices/productSlice';
import HoverLink from '../HoverLink';

interface TableBlockProps {
  TableBlockData: ITableBlock;
}

type CellContent = string | ITableCellData;

function isElement(node: HtmlDomNode): node is HtmlElement {
  return node.type === 'tag';
}

const TableBlock: React.FC<TableBlockProps> = ({ TableBlockData }) => {
  const renderCell = (
    cell: CellContent,
    key: number,
    isHeader = false
  ) => {
    const cellProps: any = {};
    let content = '';

    if (typeof cell === 'string') {
      content = cell;
    } else {
      content = cell.content;
      if (cell.colspan) cellProps.colSpan = cell.colspan;
      if (cell.rowspan) cellProps.rowSpan = cell.rowspan;
      if (cell.align) cellProps.align = cell.align;
      if (cell.verticalAlign) {
        cellProps.sx = {
          ...cellProps.sx,
          verticalAlign: cell.verticalAlign,
        };
      }
    }

    const cellStyle = {
      borderBottom: 'none',
      whiteSpace: 'normal', // Allows wrapped text to occupy multiple lines
      wordBreak: 'break-word', // Breaks long words
      ...cellProps.sx,
      ...(isHeader && {
        fontWeight: 'bold',
        fontSize: '16px',
      }),
    };

    return (
      <TableCell
        key={key}
        align={cellProps.align || 'left'}
        colSpan={cellProps.colSpan}
        rowSpan={cellProps.rowSpan}
        sx={cellStyle}
      >
        {parse(content, {
          replace: (domNode: HtmlDomNode) => {
            if (isElement(domNode) && domNode.name === 'hoverlink') {
              const innerContent = domToReact(domNode.children as HtmlDomNode[]);
              const { href } = domNode.attribs || {};

              return <HoverLink to={href}>{innerContent}</HoverLink>;
            }
          },
        })}
      </TableCell>
    );
  };

  const computeBackgroundColors = () => {
    const backgroundColors: string[] = [];
    let isOdd = true;
    let rowspanTrackers: {
      startRow: number;
      endRow: number;
      color: string;
    }[] = [];

    const rows = TableBlockData.rows;

    for (let i = 0; i < rows.length; i++) {
      rowspanTrackers = rowspanTrackers.filter((r) => r.endRow >= i);

      const activeRowspan = rowspanTrackers.find(
        (r) => r.startRow <= i && r.endRow >= i
      );

      if (activeRowspan) {
        // Use the color from the active rowspan
        backgroundColors[i] = activeRowspan.color;
      } else {
        // No active rowspan, toggle the isOdd flag
        isOdd = !isOdd;
        const bgColor = isOdd ? 'rgba(0, 0, 0, 0.04)' : 'transparent';
        backgroundColors[i] = bgColor;
      }

      // Process cells in this row
      const row = rows[i];
      row.forEach((cell) => {
        if (typeof cell !== 'string' && cell.rowspan && cell.rowspan > 1) {
          // There is a rowspan starting here
          rowspanTrackers.push({
            startRow: i,
            endRow: i + cell.rowspan - 1,
            color: backgroundColors[i],
          });
        }
      });
    }

    return backgroundColors;
  };

  const backgroundColors = computeBackgroundColors();

  return (
    <Box>
      {TableBlockData.title && (
        <Typography variant="h5" gutterBottom>
          {TableBlockData.title}
        </Typography>
      )}
      <Box sx={{ overflowX: 'auto' }}>
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: '12px',
            overflow: 'hidden',
            boxShadow: 0,
            minWidth: 650,
          }}
        >
          <Table aria-label="product specifications table" sx={{ border: 'none' }}>
            <TableHead>
              <TableRow>
                {TableBlockData.headers.map((header, i) =>
                  renderCell(header, i, true)
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {TableBlockData.rows.map((row, i) => (
                <TableRow
                  key={i}
                  sx={{
                    backgroundColor: backgroundColors[i],
                    '&:last-child td': { borderBottom: 'none' },
                  }}
                >
                  {row.map((cell, j) => renderCell(cell, j))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default TableBlock;
