import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  TextField,
  Popper,
  Paper,
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { searchCatalog, clearSearch } from '../../store/slices/searchSlice';
import debounce from 'lodash/debounce';

interface SearchBarProps {
  anchorEl: HTMLElement | null;
  onClose: (event?: React.MouseEvent<HTMLElement>) => void;
  isOpen: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({ anchorEl, onClose, isOpen }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { results, loading } = useAppSelector(state => state.search);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!isOpen) {
      setSearchQuery('')
    }
  }, [isOpen]);

  const debouncedSearch = debounce((query: string) => {
    if (query.length >= 3) {
      dispatch(searchCatalog(query));
    }
  }, 300);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
    return () => {
      dispatch(clearSearch());
    };
  }, [isOpen, dispatch]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };

  const handleItemClick = (type: 'category' | 'products', id: string) => {
    onClose();
    navigate(type === 'category' ? `/catalog/${id}` : `/products/${id}`);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && searchQuery.trim()) {
      onClose();
      navigate(`/search?q=${encodeURIComponent(searchQuery.trim())}`);
    }
  };

  return (
    <Popper
      open={isOpen}
      anchorEl={anchorEl}
      placement="bottom-end"
      style={{ zIndex: 1301, width: '400px' }}
      onMouseEnter={(e) => {
        e.stopPropagation();
      }}
      onMouseLeave={(e) => {
        if (!e.relatedTarget || !e.currentTarget.contains(e.relatedTarget as Node)) {
          onClose(e);
        }
      }}
    >
      <Paper elevation={3} sx={{ p: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Поиск по каталогу..."
          value={searchQuery}
          onChange={handleSearch}
          onKeyPress={handleKeyPress}
          inputRef={inputRef}
          InputProps={{
            endAdornment: loading && <CircularProgress size={20} />,
          }}
        />
        
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
          <Button
            variant="text"
            size="small"
            onClick={() => navigate('/search')}
          >
            Расширенный поиск
          </Button>
        </Box>
        
        {searchQuery.length >= 3 && (
          <Box sx={{ mt: 2, maxHeight: '400px', overflow: 'auto' }}>
            {results.categories.length > 0 && (
              <>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
                  Категории
                </Typography>
                <List>
                  {results.categories.map(category => (
                    <ListItem key={category.id} disablePadding>
                      <ListItemButton onClick={() => handleItemClick('category', category.id)}>
                        <ListItemText primary={category.name} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </>
            )}
            
            {results.products.length > 0 && (
              <>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
                  Товары
                </Typography>
                <List>
                  {results.products.map(product => (
                    <ListItem key={product.id} disablePadding>
                      <ListItemButton onClick={() => handleItemClick('products', product.id)}>
                        <ListItemText primary={product.name} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </>
            )}
            
            {!loading && results.categories.length === 0 && results.products.length === 0 && (
              <Typography color="textSecondary" align="center">
                Ничего не найдено
              </Typography>
            )}
          </Box>
        )}
      </Paper>
    </Popper>
  );
};

export default SearchBar; 