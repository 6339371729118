import React, { useEffect, useState } from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useNavbarStyle } from '../components/NavbarStyleContext';

const ErrorPage: React.FC = () => {
  const navigate = useNavigate();
  const { setNavbarStyle } = useNavbarStyle();

  const [isShaking, setIsShaking] = useState(false);

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    setNavbarStyle('light');
    const shakeInterval = setInterval(() => {
      setIsShaking(true);
      setTimeout(() => setIsShaking(false), 500); // длительность тряски
    }, 3000); // интервал между трясками

    return () => {
      clearInterval(shakeInterval);
      setNavbarStyle('dark');
    };
  }, [setNavbarStyle]);

  return (
    <Container
      sx={{
        textAlign: 'center',
        minHeight: '40vh',
        my: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="h2"
        color="error"
        gutterBottom
        sx={{
          animation: isShaking ? 'shake 0.5s ease' : 'none',
        }}
      >
        Ошибка!
      </Typography>
      <Typography variant="h5" gutterBottom>
        Что-то пошло не так. Страница недоступна.
      </Typography>
      <Box sx={{ mt: 4 }}>
        <Button
          variant="contained"
          onClick={handleGoBack}
          sx={{
            backgroundColor: 'rgba(240,240,240)',
            backdropFilter: 'blur(8px)',
            color: 'black',
            '&:hover': {
              backgroundColor: 'rgba(200, 200, 200)',
            },
          }}
        >
          Вернуться назад
        </Button>
      </Box>
      <style>
        {`
          @keyframes shake {
            0% { transform: translate(1px, 1px) rotate(0deg); }
            10% { transform: translate(-1px, -2px) rotate(-1deg); }
            20% { transform: translate(-3px, 0px) rotate(1deg); }
            30% { transform: translate(3px, 2px) rotate(0deg); }
            40% { transform: translate(1px, -1px) rotate(1deg); }
            50% { transform: translate(-1px, 2px) rotate(-1deg); }
            60% { transform: translate(-3px, 1px) rotate(0deg); }
            70% { transform: translate(3px, 1px) rotate(-1deg); }
            80% { transform: translate(-1px, -1px) rotate(1deg); }
            90% { transform: translate(1px, 2px) rotate(0deg); }
            100% { transform: translate(1px, -2px) rotate(-1deg); }
          }
        `}
      </style>
    </Container>
  );
};

export default ErrorPage;
