import React from 'react';
import { Fab, Button, useTheme, keyframes } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import { useRequestModal } from '../../contexts/RequestModalContext';

interface RequestButtonProps {
  variant?: 'icon' | 'text';
  filled?: boolean;
  label?: string;
  fixed?: boolean;
  sx?: object;
  dark?: boolean;
}

const RequestButton: React.FC<RequestButtonProps> = ({
                                                       variant = 'icon',
                                                       filled = true,
                                                       label = 'Оставить заявку',
                                                       fixed = false,
                                                       sx = {},
                                                       dark = false,
                                                     }) => {
  const theme = useTheme();
  const { openModal } = useRequestModal();

  const color = variant === 'text' ? dark ? 'rgba(0, 0, 0, 1)' : 'rgba(255, 255, 255, 1)' : theme.palette.error.main;

  const pulseIcon = keyframes`
      0% {
          box-shadow: 0 0 0 0 rgba(211, 47, 47, 0.4);
          transform: scale(1);
      }
      25% {
          transform: scale(1.1);
      }
      70% {
          box-shadow: 0 0 0 15px rgba(211, 47, 47, 0);
          transform: scale(1);
      }
      100% {
          box-shadow: 0 0 0 0 rgba(211, 47, 47, 0);
          transform: scale(1);
      }
  `;

  const pulseText = keyframes`
      0% {
          ${dark ? 'box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.8)' : 'box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4)'};
      }
      70% {
          box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
      }
      100% {
          box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
      }
  `;

  const hoverAnimationIcon = keyframes`
      0% {
          transform: scale(1);
          box-shadow: 0 0 0 0 rgba(211, 47, 47, 0.4);
      }
      100% {
          transform: scale(1.15);
          box-shadow: 0 0 0 30px rgba(211, 47, 47, 0);
      }
  `;

  const hoverAnimationText = keyframes`
      0% {
          transform: scale(1);
          box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
      }
      100% {
          transform: scale(1.1);
          box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
      }
  `;

  const shine = keyframes`
      0% {
          left: -50px;
      }
      25% {
          left: -50px;
      }
      75% {
          left: calc(100% + 40px);
      }
      100% {
          left: calc(100% + 40px);
      }
  `;

  const commonStyles = {
    ...(fixed ? {
      position: 'fixed',
      bottom: 20,
      right: 20,
      zIndex: 1000,
    } : {}),
    backgroundColor: filled ? color : 'transparent',
    backdropFilter: !filled ? 'blur(8px)' : 'none',
    border: !filled ? `2px solid ${color}` : 'none',
    color: filled ? 'white' : color,
    transition: 'all 0.3s ease-in-out',
    ...sx,
    '&:hover': {
      backgroundColor: filled
        ? theme.palette.error.dark
        : 'rgba(255, 255, 255, 0.1)',
      animation: variant === 'icon' ? `${hoverAnimationIcon} 0.5s ease-in-out` : `${hoverAnimationText} 0.5s ease-in-out`,
      transform: variant === 'icon' ? 'scale(1.15)' : 'scale(1.1)',
    },
    ...(variant === 'icon' && filled && {
      animation: `${pulseIcon} 2s infinite`,
    }),
    ...(variant === 'text' && {
      animation: `${pulseText} 2s infinite`,
    }),
    '&::before': variant === 'text' ? {
      content: '""',
      position: 'absolute',
      top: 0,
      width: '30px',
      height: '100%',
      background: dark ? 'rgba(255, 255, 255, 0.9)' : 'rgba(255, 255, 255, 0.7)',
      filter: 'blur(15px)',
      transform: 'skewX(-15deg)',
      animation: `${shine} 5s infinite`,
    } : {},
    '&:active': {
      transform: variant === 'icon' ? 'scale(0.9)' : 'scale(0.95)',
      transition: 'transform 0.1s ease-in-out',
      '&::before': variant === 'text' ? {
        opacity: 0.9,
        filter: 'blur(10px)',
      } : {},
      backgroundColor: filled
        ? theme.palette.error.dark
        : 'rgba(255, 255, 255, 0.05)',
      boxShadow: filled
        ? '0 0 0 0 rgba(211, 47, 47, 0.4)'
        : 'none',
    },
  };

  if (variant === 'icon') {
    return (
      <Fab
        color="primary"
        aria-label="request"
        onClick={openModal}
        sx={commonStyles}
      >
        <ChatIcon />
      </Fab>
    );
  }

  return (
    <Button
      variant={filled ? 'contained' : 'outlined'}
      onClick={openModal}
      startIcon={<ChatIcon />}
      sx={{
        ...commonStyles,
        padding: '10px 20px',
        borderRadius: '25px',
        textTransform: 'none',
        fontSize: '16px',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {label}
    </Button>
  );
};

export default RequestButton; 