import React, { useEffect, useRef, useState } from 'react';
import {
  Typography,
  Box,
  Container,
  Button,
  Grid2,
  Grow, Slide, Zoom,
} from '@mui/material';
import { Parallax } from 'react-scroll-parallax';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { fetchCategories } from '../store/slices/catalogSlice';
import CategoryCard from '../components/CategoryCard';
import AnimatedCard from '../components/AnimatedCard';
import { useNavbarStyle } from '../components/NavbarStyleContext';
import RequestButton from '../components/RequestButton/RequestButton';

const HomePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { categories, loading, error } = useAppSelector((state) => state.catalog);

  const catalogRef = useRef<HTMLDivElement | null>(null);
  const navBarHeight = 80;

  const [showContent, setShowContent] = useState(false);

  const { setNavbarStyle } = useNavbarStyle();

  useEffect(() => {
    setNavbarStyle('dark');

    return () => {
      setNavbarStyle('dark');
    };
  }, [setNavbarStyle]);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    const contentTimeout = setTimeout(() => {
      setShowContent(true);
    }, 500);
    return () => clearTimeout(contentTimeout);
  }, []);

  const scrollToCatalog = () => {
    if (catalogRef.current) {
      const offsetTop = catalogRef.current.getBoundingClientRect().top + window.pageYOffset - navBarHeight;
      window.scrollTo({ top: offsetTop, behavior: 'smooth' });
    }
  };

  return (
    <>
      <style>
        {`
      @keyframes breathAnimation {
        0% { transform: scale(1); }
        50% { transform: scale(1.1); }
        100% { transform: scale(1); }
      }
    `}
      </style>
      <Box
        component="div"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh',
          zIndex: -1,
          overflow: 'hidden',
          '::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1,
          }
        }}
      >
        <Parallax speed={-50} style={{ minHeight: '100vh', width: '100%' }}>
          <video
            autoPlay
            muted
            loop
            style={{
              width: '100%',
              height: '100vh',
              objectFit: 'cover',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: -1,
            }}
          >
            <source src="https://s3.timeweb.cloud/b510c84d-test/videos/laser_cnc.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Parallax>
      </Box>

      <Container maxWidth="xl">
        <Box p={2}>
          <Container
            sx={{
              paddingTop: '20vh',
              width: { xs: 'auto', sm: 500, md: 700 },
              height: '90vh',
              borderRadius: 4,
              zIndex: 1,
              color: '#fff',
              textAlign: 'center',
            }}
          >
            <Grow in={showContent} timeout={1000}>
              <Typography
                gutterBottom
                sx={{ fontWeight: '800', fontSize: { xs: '5vw', sm: '30px', md: '36px' } }}
              >
                Поставки механической и электрической продукции для промышленности и бизнеса
              </Typography>
            </Grow>

            <Slide
              direction="up"
              in={showContent}
              timeout={1000}
              style={{ transitionDelay: '500ms' }}
            >
              <Typography
                variant="h5"
                sx={{ textAlign: 'center', fontSize: { xs: '4vw', sm: '18px', md: '24px' } }}
              >
                {'Компания "Юджен" предлагает широкий ассортимент механической и электрической продукции из Китая для различных отраслей промышленности и частного бизнеса. Надёжность, качество и своевременность поставок — наши приоритеты.'}
              </Typography>
            </Slide>

            <Zoom in={showContent} timeout={1000} style={{ transitionDelay: '1000ms' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', mt: '20%' }}>
                <Button
                  variant="contained"
                  onClick={scrollToCatalog}
                  sx={{
                    color: 'black',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    borderRadius: '10px',
                    backdropFilter: 'blur(5px)',
                    padding: '10px 20px',
                    fontSize: '16px',
                    transition: 'background-color 0.3s ease',
                    animation: 'breathAnimation 1s ease-in-out infinite',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.6)',
                    },
                  }}
                >
                  Каталог
                </Button>
                
                <RequestButton 
                  variant="text"
                  filled={false}
                  label="Оставить заявку"
                  sx={{mt: '10px'}}
                />
              </Box>
            </Zoom>
          </Container>

          {loading && <Typography>Загрузка категорий...</Typography>}
          {error && <Typography color="error">Ошибка: {error}</Typography>}

          {!loading && !error && (
            <Box ref={catalogRef}>
              <Typography variant="h3" py={5}>
                Наш каталог
              </Typography>
              <Grid2 container spacing={2} columns={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}>
                {categories
                  .filter((category) => category.type === 'root')
                  .map((category, idx) => (
                    <Grid2 key={category.id} size={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}>
                      <AnimatedCard
                        initialDelay={200}
                        stepDelay={50}
                        animationDuration={1000}
                      >
                        <CategoryCard category={category} />
                      </AnimatedCard>
                    </Grid2>
                  ))}
              </Grid2>
            </Box>
          )}
        </Box>
      </Container>
    </>
  );
};

export default HomePage;
