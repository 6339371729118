// src/pages/ContactsPage.tsx

import React, { useEffect } from 'react';
import Contacts from '../components/Contacts/Contacts';
import { useNavbarStyle } from '../components/NavbarStyleContext';

const ContactsPage: React.FC = () => {
  const { setNavbarStyle } = useNavbarStyle();

  useEffect(() => {
    setNavbarStyle('light'); // Устанавливаем светлый стиль для Navbar на этой странице

    return () => {
      setNavbarStyle('dark'); // Возвращаем темный стиль при уходе со страницы
    };
  }, [setNavbarStyle]);

  return (
    <div style={{ backgroundColor: '#f9f9f9', minHeight: '100vh' }}>
      <Contacts />
    </div>
  );
};

export default ContactsPage;
