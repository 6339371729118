import { IContentBlock } from '../../store/slices/productSlice';

export const ServicesData: IContentBlock[] = [
  {
    type: 'title',
    text: 'Прайс лист на услуги по работе с Китаем',
    level: 1,
  },
  {
    type: 'title',
    text: 'Цена может изменятся в связи с изменением курсов валют',
    level: 3,
  },
  {
    type: 'text',
    title: 'Выкуп товаров с 1688, Таобао и напрямую с фабрик',
    content: '<b>ООО «ТК Юджен»</b> <i>китайская компания основанная гражданином России, живущим в Китае, с представительством в Шанхае.</i><br><b>Мы помогаем предпринимателям из России и стран СНГ покупать ТОВАРЫ ОПТОМ в Китае и не берем комиссию (0%) за товар. Работаем без процентов.</b>',
  },
  {
    type: 'table',
    title: 'Консолидация товаров на складе в Китае',
    headers: [
      'Услуги',
      {
        content:
          'Цена за 1 шт (если пуховик, куртка, джинсы)<br>Цена за пакет или коробку (если товар мелкий: трусы, перчатки)',
        colspan: 3,
        align: 'center',
      },
    ],
    rows: [
      ['', { content: '<b>ОДЕЖДА И МЕЛКИЕ ТОВАРЫ</b>', colspan: 3, align: 'center' }],
      [
        '<b>Количество</b>',
        '<b>до 500 шт</b>',
        '<b>500 - 1000 шт</b>',
        '<b>1000+ шт</b>',
      ],
      [
        'Подсчет количества',
        '1.1 ¥ (0.15 $)',
        '0.9 ¥ (0.12 $)',
        '0.6 ¥ (0.077 $)',
      ],
      [
        'Проверка размера, цвета (выборочно фотографии)',
        '1.7 ¥ (0.23 $)',
        '1.4 ¥ (0.19 $)',
        '0.9 ¥ (0.12 $)',
      ],
      [
        'Упаковка в плотную пленку и скотч',
        { content: '<b>от 214 ¥ (30 $)</b> за куб.', colspan: 3, align: 'center' },
      ],
      ['', { content: '<b>ДЛЯ ЭЛЕКТРОННЫХ ПРОДУКТОВ И ГАДЖЕТОВ</b>', colspan: 3, align: 'center' }],
      [
        'Подсчет количества',
        '1.1 ¥ (0.15 $)',
        '0.9 ¥ (0.12 $)',
        '0.6 ¥ (0.078 $)',
      ],
      [
        'Проверка модели, цвета',
        '1.7 ¥ (0.23 $)',
        '1.4 ¥ (0.19 $)',
        '0.9 ¥ (0.12 $)',
      ],
      [
        'Упаковка в плотную пленку и скотч',
        { content: '<b>от 214 ¥ (30 $)</b> за куб.', colspan: 3, align: 'center' },
      ],
      [
        'Паллетирование (поставить на паллет)',
        {
          content: '<b>от 55 ¥ (7.7 $)</b> за паллетирование (поставить коробки, пакеты на паллет и закрепить)',
          colspan: 3,
          align: 'center',
        },
      ],
      ['', { content: '<b>МЕХАНИЧЕСКОЕ ОБОРУДОВАНИЕ</b>', colspan: 3, align: 'center' }],
      [
        '<b>Количество</b>',
        '<b>до 10 шт</b>',
        '<b>10 - 50 шт</b>',
        '<b>50 - 100 шт</b>',
      ],
      [
        'Проверка модели, цвета',
        '10.7 ¥ (1.5 $)',
        '7.7 ¥ (1 $)',
        '5.5 ¥ (0.77 $)',
      ],
      [
        'Упаковка в плотную пленку и скотч',
        '16.6 ¥ (2.32 $)',
        '11.7 ¥ (1.5 $)',
        '8.9 ¥ (1.24 $)',
      ],
      [
        'Обрешетка или паллет-борт (деревянная коробка)',
        {
          content: '<b>от 35 Y (4,64 $)</b> за упаковку в деревянный паллет-борт (цена зависит от размера)',
          colspan: 3,
          align: 'center',
        },
      ],
      [
        'Испытание оборудование',
        { content: 'Цена индивидуальная и зависит от сложности оборудования', colspan: 3, align: 'center' },
      ],
    ],
  },
  {
    type: 'text',
    title: 'Склад для логистических услуг ',
    content: 'Услуга предоставляется по договоренности, цена рассчитывается индивидуально',
  },
  {
    type: 'combined',
    content: [
      {
        type: 'text',
        title: 'Контроль качества товара',
        content: '<i>О необходимости проверки, а также ее глубине договаривайтесь с менеджером заранее!</i>',
      },
      {
        type: 'list',
        items: [
          '<b>Проверка качества по 10+ параметрам:</b> подсчет количества, обмер для определения объема, взвешивание, оценка внешнего вида (цвет, дизайн) и комплектации, проверка заявленной функциональности, тестирование, маркировка, подготовка отчета.',
          '<b>Тестирование на работоспособность</b> приборов, гаджетов, электроинструмента и других товаров, которые можно проверить на выполнение заложенных в них функций.',
          '<b>Фото, видео и текстовый отчет</b> по результатам проверки. Если нужно, то подготовим расширенный текстовый отчет, дополнив его фотографиями и видеозаписью. Вы получите полную информацию о товаре удаленно.',
          '<b>Возврат и обмен бракованного товара</b> — найдем некачественный товар, который отличается от заказанного и договоримся с производителем о обмене или возврате. Вы получите в полном объеме то, что заказывали и сохраните свои деньги.',
        ],
      },
      {
        type: 'text',
        title: 'Стоимость услуг по контролю качества продукции',
        content: 'Обратившись к нам за контролем качества товара в Китае, вы получите комплекс услуг: бесплатное хранение товара, проверку, консолидацию и упаковку для отправки в Россию и Казахстан. Проработаем логистический маршрут и поможем со страховкой груза.',
      },
    ],
  },
  {
    type: 'combined',
    content: [
      {
        type: 'list',
        title: 'Проверка китайских поставщиков',
        items: [
          '<b>Оценка компании от 1 часа до 10 рабочих дней.</b>',
          '<b>Проверка поставщиков в Китае — 6 городов или пригороды:</b> Гуанчжоу, Шэньчжэне, Пекин, Шанхай, Фошань, Дунгуань, Иу. Если компания находится в другом городе, то напишите нам, подумаем можем ли её проверить.',
          '<b>Все уровни проверки поставщиков в Китае:</b> оценка компании, юридическая чистота, квалификация специалистов, качество продукта, проверка документов, оценка характера и личности руководства, поиск информации в китайской части интернета (отзывов и рекомендаций).',
          '<b>Отчет 10+ пунктов на русском языке</b> в зависимости от глубины проверки, которую вы заказываете. Рассказываем не только факты, но и свои ощущения от взаимодействия с бизнесом проверяемой компании.',
          '<b>Фотографии и видеоматериалы</b> при проверке склада, производства или контрольной закупки товара. После контрольной закупки отправим вам товар экспресс-доставкой (придет от 10 дней).',
        ],
      },
      {
        type: 'text',
        title: 'Стоимость проверки поставщика в Китае',
        content: '<b>Выбирайте пакет услуг в зависимости от важности сделки.</b> Одно дело — заказать одежду на 5000$ через продаца 1688.com или Taobao.com и совсем другое работать с производителем над сложным OEM-продуктом с суммой контракта в 50.000, 100.000, 300.000$.<br>' +
          'Мы также рекомендуем обращаться в несколько компаний для проверки производителей и поставщиков. Это бывает очень полезно, когда одна компания проверяет поставщика из России, а другая работает в Китае.',
      },
      {
        type: 'table',
        headers: [
          'Паке услуг',
          {
            content:
              '<b>Удаленная проверка</b><br>' +
              '(для продавцов товаров с 1688.com и Taobao.com)\n',
            colspan: 1,
            align: 'center',
          },
          {
            content:
              '<b>Комплексная проверка</b><br>' +
              '(оптовые продавцы, производители, будущие партнеры)',
            colspan: 2,
            align: 'center',
          },
        ],
        rows: [
          ['Что входит в проверку',
            'Квалификация поставщика<br>' +
            'Кредит юридического лица<br>' +
            'Отзывы и рекомендации<br>' +
            'Проверка в Интернете<br>',
            {
              content:
                'Квалификация поставщика<br>' +
                'Кредит юридического лица<br>' +
                'Отзывы и рекомендации<br>' +
                'Проверка в Интернете<br>' +
                'Оценка общего состояния компании<br>' +
                'Уставные документы<br>' +
                'Контрольная закупка<br>' +
                'Качество продукта<br>' +
                'Характер и личность руководства<br>' +
                'Фотографии, видео (склад, продукт, производство)',
              colspan: 2,
            }],
          [
            'Сроки',
            'от 1 часа (день в день)',
            { content: 'от 3 до 10 рабочих дней', colspan: 2 },
          ],
          [
            'Города',
            'Любые',
            'Гуанчжоу<br>Шэньчжэнь',
            'Пекин<br>Шанхай<br>Фошань<br>Дунгуань<br>Иу',
          ],
          [
            'Цена',
            '360 Y (50$)',
            '2140 Y (300$)',
            '3135 Y (440$)',
          ],
        ],
      },
      {
        type: 'divider'
      },
      {
        type: 'text',
        content: '<b>Получить скидку можно</b>, если сформировать индивидуальный пакет услуг. Например, вы хотите проверить производителя товара, но вам не интересно знать характер и личность руководителя, видеть производство на видео или делать контрольную закупку товаров, которые производит компания. Откажитесь от этих пунктов при заказе услуги проверка поставщика в Китае, чтобы заплатить меньше.',
      },
    ],
  },
  {
    type: 'combined',
    content: [
      {
        type: 'text',
        title: 'Помощь с организацией OEM/ODM-производства (пример помощи)',
        content: '<b>Подпишите контракт с поставщиком в Китае с нашей поддержкой</b> в Гуанчжоу, Шэньчжэне, Фошане, Шанхае, ' +
          'Пекине, Тяньцзине или Шаньдуне. В этих городах сосредоточено большая часть производственных площадок. ' +
          'Здесь можно заказать любой OEM и ODM товар — от мебели, гаджетов и одежды, до оборудования, станков, ' +
          'инструмента и производственной линии. <b>OEM (Original Equipment Manufacturer)</b> — производитель оригинального ' +
          'оборудования по контракту. Можно взять готовый товар с фабрики, изменить цвет, логотип и запустить производство ' +
          'в Китае под своей торговой маркой. <b>ODM (Original Design Manufacturer)</b> — производство оригинального дизайна по ' +
          'контракту. Изготовление уникального товара по техническому заданию и чертежам (а лучше по прототипу). Это ' +
          'тернистый путь, так как предстоит много переговоров с производителем, а контракт должен защитить ваши деньги, ' +
          'вложенные в прототип.)<br>' +
          '<b>Задача компании</b> — перевести документы, провести переговоры после предварительных договоренностей, помочь с ' +
          'составлением и заключение контракта, проконтролировать работы производства, выполнить консолидацию и отправку товаров.',
      },
      {
        type: 'table',
        headers: [
          'Услуга',
          'Условие',
          'Стоимость',
        ],
        rows: [
          [
            'Перевод документов ',
            'За 1 лист',
            'от 328 Y (46 $)',
          ],
          [
            { content: 'от 3 до 10 рабочих дней', rowspan: 2 },
            'Простой продукт (одежда, обувь)',
            'от 1090 Y (153 $)',
          ],
          [
            'Сложный технический продукт',
            'от 2728 Y (383 $)',
          ],
          [
            { content: 'от 3 до 10 рабочих дней', rowspan: 4 },
            '10000 — 50000$ (цена контракта)',
            '3561 Y (500$)',
          ],
          [
            '50000 — 100000$',
            '49852 Y (700$)',
          ],
          [
            '100000 — 500000$',
            '7121 Y (1000$)',
          ],
          [
            '500000 — 1000000$',
            '14242 Y (2000$)',
          ],
        ],
      },
    ],
  },
  {
    type: 'text',
    title: 'Помощь в организации доставки товара из Китая.',
    content: '',
  },
  {
    type: 'title',
    text: 'Бизнес-тур в Китай по индивидуальному маршруту',
    level: 1,
  },
  {
    type: 'combined',
    content: [
      {
        type: 'list',
        items: [
          'Индивидуальный маршрут по целям',
          'Предварительный поиск поставщиков',
          'Трансфер в аэропорт и на фабрики, рынки',
          'Переводчик для переговоров и договора',
          'Юридическое участие в контракте',
        ],
      },
      {
        type: 'text',
        content: 'Цель нашего индивидуального бизнес-тура в Китай — найти поставщика нужного товара, доставить вас на фабрику, провести переговоры по условиям и помочь безопасно совершить сделку (вплоть до юридического участия в контракте). <b>Длительность поездки зависит от количества целей и маршрута, но в среднем занимает 2 дня.</b>',
      },
    ],
  },
  {
    type: 'combined',
    content: [
      {
        type: 'text',
        title: 'Стоимость индивидуального бизнес-тура в Китай',
        content: 'Организуем бизнес-поездку в Китай по согласованным фабрикам для выбора поставщика, заключения договора, проведения оплаты и начала сотрудничества',
      },
      {
        type: 'table',
        headers: [
          { content: '«Менеджер+комфорт»', align: 'center' },
          { content: '«Бизнес-премиум»', align: 'center' },
        ],
        rows: [
          [{ content: 'Поиск поставщиков, фабрик', align: 'center', colspan: 2 }],
          [{ content: 'Встреча в аэропорту', align: 'center', colspan: 2 }],
          [{ content: 'Сразу едем на фабрику', align: 'center', colspan: 2 }],
          [{ content: 'Возможно посещение рынков', align: 'center', colspan: 2 }],
          [{
            content: 'Переводчик на 2 дня <b>(менеджер)</b>',
            align: 'center',
          }, { content: 'Переводчик на 2 дня <b>(руководитель)</b>', align: 'center' }],
          [{ content: 'Трансфер до аэропорта', align: 'center', colspan: 2 }],
          [{ content: 'Участие в заключении контракта', align: 'center', colspan: 2 }],
          [{ content: 'Все дополнительные услуги', align: 'center', colspan: 2 }],
          [{ content: '<b>$1500</b>', align: 'center' }, { content: '<b>$2500</b>', align: 'center' }],
          [{
            content: '<b>Бронь $100 за поиск поставщиков.</b> Соберем контакты 3—5 потенциальных поставщиков и передадим информацию вам ($100 вычтем из цены за тур).',
            colspan: 2,
          }],
          [{
            content: '<b>50% предоплата</b> за бизнес-тур по Китаю после согласования маршрута и полной уверенности, что вы готовы ехать.',
            colspan: 2,
          }],
        ],
      },
    ],
  },
  {
    type: 'list',
    title: 'Что еще включает в себя бизнес-тур в Китай (дополнительные услуги)',
    items: [
      'Помощь менеджера с бронью отеля в Шанхае',
      'Оформление китайской SIM-карты',
      'Настройка лучшего китайского VPN',
      'Помощь с обменом валюты и переводами',
      'Регистрация в WeChat для общения',
      'Юридическое участие в контракте',
    ],
  },
  {
    type: 'table',
    headers: [],
    rows: [
      [{
        content: '<b>Трехсторонний контракт (вы, ООО «ТК Юджен», поставщик)</b> — отдельная услуга. Мы помогаем заключить контракт и участвуем в нем с вашей стороны. Это нужно для гарантии безопасности сделки. Если в будущем возникнет недопонимание с поставщиком, то его будет легче разрешить в Китае с нашей помощью. Мы легко сможем помочь как участник сделки.',
        align: 'center',
        colspan: 2,
      }],
      [{
        content: 'До $100 000 берем комиссию до 2% за участие.',
        align: 'center',
      }, { content: 'Более $100 000 берем комиссию до 3% за участие.', align: 'center' }],
    ],
  },
  {
    type: 'list',
    title: 'Что не входит в стоимость бизнес-тура',
    items: [
      'Выбор рейса, поиск и покупка билетов',
      'Бронирование отеля (под ключ)',
      'Развлечения и туризм',
    ],
  },
  {
    type: 'text',
    content: 'Наш менеджер может подсказать хороший отель, но не станет искать билеты, свободный номер и ' +
      'планировать ваше свободное время в Китае. Расскажем, как правильно забронировать номер.<br>' +
      '<b>Мы отказываем в услуге бизнес-тур в Китай тем клиентам,</b> которые хотят поехать без конкретной цели. ' +
      'Например, вдохновиться на бизнес, изучая различные товары на оптовых рынках. Такую поездку лучше совершить ' +
      'в составе группового тура, где 15—20 предпринимателей весь день возят по разным оптовым рынкам. Так вы ' +
      'будете находиться в бизнес-среде и быстрее найдете идею для торговли.',
  },
  {
    type: 'combined',
    content: [
      {
        type: 'text',
        title: 'Как заказать бизнес-тур в Китай',
        content: 'Пришлите на почту <HoverLink>yuzhen.torg@gmail.com</HoverLink> или в WhatsApp: <HoverLink>+79109351152</HoverLink>; <HoverLink>+79108364995</HoverLink>; <HoverLink>+86 155-021-063-10</HoverLink>  информацию для организации бизнес-тура.',
      },
      {
        type: 'list',
        items: [
          'Даты поездки в Китай',
          'Количество людей',
          'Какие товары нужны',
          'Сумма планируемых закупок',
          'Свои контакты (желательно WhatsApp)',
        ],
      },
      {
        type: 'text',
        content:
          '<b>Пришлите нам подробное описание нужного товара</b> (характеристики, сфера применения, на что обратить внимание ' +
          'при выборе), во вложении прикрепите фотографии и изображения товара, а если их нет, то ближайших аналогов.<br>' +
          'Предоставьте нам максимум информации по необходимым товарам. Это поможет нам в течение 5 рабочих дней ' +
          'подготовить список потенциальных поставщиков. Пришлем список вам на согласование.',
      },
    ],
  },
  {
    type: 'text',
    title: 'Оплата брони и 50% предоплата',
    content:
      'Оплатить бронь в $100 за поиск поставщика можно <b>рублевым или юаневым платежом.</b>',
  },
  {
    type: 'combined',
    content: [
      {
        type: 'list',
        title: 'Этапы проведения бизнес-поездки по Китаю',
        items: [
          '<b>Этап №1.</b> Отправляете нам дату поездки, количество людей, предполагаемый объем закупки, контакты для обратной связи, а также всю возможную информацию об интересующих вас товарах.',
          '<b>Этап №2.</b> Менеджер связывается с вами в течение 2 рабочих дней после обращения для ответов на вопросы по услуге, уточнения деталей и оплаты брони за поиск поставщика.',
          '<b>Этап №3.</b> В течение 5 рабочих дней, после получения оплаты за поиск поставщика, находим потенциальных поставщиков, формируем список и отправляем вам на согласование.',
          '<b>Этап №4.</b> Как только вы согласовываете поставщиков, мы формируем маршрут и цели поездки. На этом этапе нужна 50% предоплата за бизнес-тур.',
          '<b>Этап №5.</b> В день приезда встречаем вас в аэропорту. Отвозим до отеля и помогаем поселиться, а в назначенное время едем по согласованному маршруту. Маршрут может быть скорректирован по согласованию с вами.',

        ],
      },
      {
        type: 'text',
        content:
          'Организуем бизнес-тур в Китай таким образом, чтобы вы нашли поставщика, заключили контракт и запустили новые направления своего бизнеса.<br>' +
          'Отправляйте заявки на бизнес-поездку в Китай — <HoverLink>yuzhen.torg@gmail.com</HoverLink> или пишите менеджеру в WhatsApp: <HoverLink>+79109351152</HoverLink>; <HoverLink>+79108364995</HoverLink>; <HoverLink>+86 155-021-063-10</HoverLink>',
      },
    ],
  },
  {
    type: 'title',
    text: 'Поиск поставщика',
    level: 1,
  },
  {
    type: 'list',
    items: [
      'от <b>$50</b> за услугу поиска поставщика в Китае',
      'дадим контакты в течение 3 дней',
      'оплата рублевым или юаневым переводом ',
      'возможна фото - и видеопроверка товара',
    ],
  },
  {
    type: 'combined',
    content: [
      {
        type: 'text',
        content: 'Если вы, по каким-либо причинам, не можете найти поставщика в Китае, фабрику по производству нужного товара или у вас нет времени этим заниматься — воспользуйтесь нашей услугой поиска поставщиков.',
      },
      {
        type: 'list',
        items: [
          '<b>2 уровня поиска поставщика:</b> онлайн (маркетплейсы, контакты с ярмарки), с выездом на оптовый рынок, фабрику и предоставление отчета с фото - и видеоматериалами.',
          '<b>от $50 за услугу поиска поставщика</b> — чем сложнее запрос, тем дороже стоит услуга. Например, найти поставщика детских игрушек стоит дешевле, чем деталей двигателя или инструмента.',
          '<b>Гарантируем безопасную сделку</b> — проверим каждый контакт на порядочность (долги, судебные дела) в открытых бизнес-системах, а также проанализируем отзывы на товары.',
          '<b>Персональный русскоязычный менеджер.</b> Наши менеджеры русские, которые живут в Китае и хорошо знают русский и китайский языки. Они постоянно будут на связи с вами.',
        ],
      },
    ],
  },
  {
    type: 'combined',
    content: [
      {
        type: 'list',
        title: 'Какую информацию предоставим в отчете по подбору поставщика',
        items: [
          'Список проанализированных поставщиков',
          'Список выбранных поставщиков',
          'Название компаний на английском и китайском языке',
          'Адрес компании в Китае',
          'Номер бизнес-лицензии',
          'Наличие лицензий на экспорт',
          'Ссылка на товар',
          'Стоимость товара за 1 штуку',
          'Ссылка на фото - и видеоматериалы (тариф за $400)',
          'Контакт поставщика (WeChat, WhatsApp, Email)',
        ],
      },
      {
        type: 'text',
        content: '<i>Вы можете договориться с менеджером о другой форме отчета и информации, которую хотите получить.</i>',
      },
    ],
  },
  {
    type: 'combined',
    content: [
      {
        type: 'text',
        title: 'От <b>$50</b> — стоимость поиска поставщика в Китае по техническому заданию клиента',
        content: 'Цены действуют на поиск поставщика одного товара. Если нужно найти много товаров, то могут быть предоставлены индивидуальные условия.',
      },
      {
        type: 'table',
        headers: [
          { content: '$50 (онлайн)', align: 'center' },
          { content: '$400 (с выездом)', align: 'center' },
        ],
        rows: [
          [
            { content: 'до 2 контактов в течение 2 дней', align: 'center' },
            { content: '2 — 3 контакта в течение 5 дней', align: 'center' },
          ],
          [
            { content: 'Поиск онлайн по ТЗ (без выезда)', align: 'center' },
            { content: 'Поиск по ТЗ с выездом к рынок (фабрику)', align: 'center' },
          ],
          [
            { content: 'Проверка компании (онлайн)', align: 'center' },
            { content: 'Проверка компании (с выездом)', align: 'center' },
          ],
          [
            { content: 'Отчет в WhatsApp', align: 'center' },
            { content: 'Фото и видео о товаре (от менеджера)', align: 'center' },
          ],
          [
            { content: 'Комментарии менеджера', align: 'center' },
            { content: 'Уставные документы и отчет в PDF', align: 'center' },
          ],
          [
            { content: '<b>100%</b> предоплата', align: 'center' },
            { content: '<b>50%</b> предоплата', align: 'center' },
          ],
        ],
      },
      {
        type: 'text',
        content: '<b>Ведение переговоров с поставщиком для уточнения деталей</b> по товару входят в стоимость оказания услуги. В стоимость не входит переговоры по снижению цены, если речь не идет о крупной оптовой покупке с заключением договора с нашим участием.',
      },
    ],
  },
  {
    type: 'combined',
    content: [
      {
        type: 'list',
        title: 'Что указать в заявке и техническом задании на услугу поиск производителя в Китае',
        items: [
          'ФИО',
          'Контактные данные (номер телефона, email)',
          'Какой пакет услуг хотите заказать',
          'Точное наименование товара (чехлы для смартфона Apple 15 PRO)',
          'Объем партии для заказа в штуках (500 шт.)',
          'Разброс от желаемой цены.',
          'Требования к товару (материал, цвет, функционал)',
          'Уровень желаемого качества товара',
          'Изображение товара в хорошем качестве',
          'О чем спросить поставщика',
        ],
      },
      {
        type: 'text',
        content: 'Если хотите найти поставщика одного товара или протестировать нашу услугу, то присылайте эту информацию в любом виде менеджеру на почту <HoverLink>yuzhen.torg@gmail.com</HoverLink> или в WhatsApp: <HoverLink>+79109351152</HoverLink>; <HoverLink>+79108364995</HoverLink>; <HoverLink>+86 155-021-063-10</HoverLink>',
      },
      {
        type: 'divider'
      },
      {
        type: 'text',
        content: 'В случае, если у вас несколько товаров, <HoverLink href="https://s3.timeweb.cloud/b510c84d-test/forma-zayavki.xlsx" download>заполните форму (XLS)</HoverLink> для заказа услуги «поиск производителей в Китае».<br>' +
          '<HoverLink href="https://s3.timeweb.cloud/b510c84d-test/forma-zayavki.xlsx" download>Скачать форму (XLS)</HoverLink>, чтобы заказать поиск поставщиков нескольких товаров в Китае. Заполненную форму отправьте менеджеру.',
      },
    ],
  },
  {
    type: 'text',
    title: 'Оплата: рублевым переводом',
    content: 'Оплатить услугу поиск поставщиков в Китае можно рублевым или юаневым переводом на расчетный счет компании.',
  },
  {
    type: 'combined',
    content: [
      {
        type: 'list',
        title: 'Четыре этапа поиска поставщика',
        items: [
          'Составляете и присылаете менеджеру на почту <HoverLink>yuzhen.torg@gmail.com</HoverLink> или в WhatsApp: <HoverLink>+79109351152</HoverLink>; <HoverLink>+79108364995</HoverLink>; <HoverLink>+86 155-021-063-10</HoverLink> техническое задание со всей информацией о товаре, который нужно найти.',
          'Оплачиваете услугу поиск товара в Китае. Оплатить можно рублевым или юаневым переводом на счет компании.',
          'Менеджер свяжется с вами, чтобы задать дополнительные вопросы, если в техническом задании будет недостаточно информации. Поиск поставщиков занимает от 2 до 5 рабочих дней в зависимости от пакета услуг.',
          'Отчет с результатами поиска поставщика присылаем в WhatsApp (пакет за <b>$50</b>) или файле PDF. Формат отчет зависит от пакета услуг.',
        ],
      },
      {
        type: 'text',
        content: 'Свяжитесь с менеджером, чтобы узнать больше об услуге поиска поставщика в Китае.<br>' +
          'Оставляйте заявку на услугу «поиск поставщика в Китае» на почту <HoverLink>yuzhen.torg@gmail.com</HoverLink> или пишите менеджеру в WhatsApp: <HoverLink>+79109351152</HoverLink>; <HoverLink>+79108364995</HoverLink>; <HoverLink>+86 155-021-063-10</HoverLink>'
      }
    ],
  },
];
