// src/pages/HowToBuyPage.tsx

import React, { useEffect } from 'react';
import HowToBuy from '../components/HowToBuy/HowToBuy';
import { useNavbarStyle } from '../components/NavbarStyleContext';

const HowToBuyPage: React.FC = () => {
  const { setNavbarStyle } = useNavbarStyle();

  useEffect(() => {
    setNavbarStyle('light'); // Устанавливаем светлый стиль для Navbar на этой странице

    return () => {
      setNavbarStyle('dark'); // Возвращаем темный стиль при уходе со страницы
    };
  }, [setNavbarStyle]);

  return (
    <div style={{ backgroundColor: '#f9f9f9', minHeight: '100vh' }}>
      <HowToBuy />
    </div>
  );
};

export default HowToBuyPage;
