import React, { useEffect } from 'react';
import { Box, Grid2 } from '@mui/material';
import { Category } from '../../store/slices/catalogSlice';
import CarouselList from '../CarouselList';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchProductsByCategory } from '../../store/slices/productSlice';
import AnimatedCard from '../AnimatedCard';
import CategoryCard from '../CategoryCard';

interface CategoryListProps {
  categories: Category[];
  horizontal?: boolean;
}

const CategoryList: React.FC<CategoryListProps> = ({ categories, horizontal = false }) => {
  const dispatch = useAppDispatch();
  const productsByCategory = useAppSelector(state => state.products.byCategory);

  useEffect(() => {
    const fetchProducts = async () => {
      const finalCategories = categories.filter(category => category.type === 'final');
      const categoriesToFetch = finalCategories.filter(
        category => !productsByCategory[category.id]
      );

      if (categoriesToFetch.length > 0) {
        await Promise.all(
          categoriesToFetch.map(category => 
            dispatch(fetchProductsByCategory(category.id))
          )
        );
      }
    };

    fetchProducts();
  }, [categories, dispatch, productsByCategory]);

  if (horizontal) {
    return (
      <CarouselList slidesToShow={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}>
        {categories.map((category, idx) => {
          const isFinal = category.type === 'final';
          const products = isFinal ? productsByCategory[category.id]?.products : undefined;

          return (
            <Box key={category.id} sx={{ margin: 1 }}>
              <AnimatedCard
                initialDelay={100}
                stepDelay={50}
                animationDuration={1000}
              >
                <CategoryCard
                  category={category}
                  products={products}
                  height={320}
                  maxHeight={320}
                />
              </AnimatedCard>
            </Box>
          );
        })}
      </CarouselList>
    );
  } else {
    return (
      <Grid2 container spacing={2} columns={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}>
        {categories.map((category, idx) => {
          const isFinal = category.type === 'final';
          const products = isFinal ? productsByCategory[category.id]?.products : undefined;

          return (
          <Grid2 key={category.id} size={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}>
            <AnimatedCard
              initialDelay={100}
              stepDelay={50}
              animationDuration={1000}
            >
              <CategoryCard
                category={category}
                products={products}
                height={320}
                maxHeight={320}
              />
            </AnimatedCard>
          </Grid2>
        )})}
      </Grid2>
    );
  }
};

export default CategoryList;
