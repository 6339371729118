import React, { useState } from 'react';
import { Link as MuiLink, SxProps, Theme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

interface HoverLinkProps {
  to?: string;
  label?: string;
  color?: string;
  underlineColor?: string;
  variant?:
    | 'inherit'
    | 'button'
    | 'overline'
    | 'caption'
    | 'body2'
    | 'body1'
    | 'subtitle2'
    | 'subtitle1'
    | 'h6'
    | 'h5'
    | 'h4'
    | 'h3'
    | 'h2'
    | 'h1';
  sx?: SxProps<Theme>;
  className?: string;
  onClick?: any;
  children?: React.ReactNode;
  download?: boolean | string;
}

const HoverLink: React.FC<HoverLinkProps> = ({
                                               to,
                                               label,
                                               color = 'inherit',
                                               underlineColor = 'black',
                                               variant = 'h6',
                                               sx = {},
                                               className,
                                               onClick,
                                               children,
                                               download,
                                             }) => {
  const [hovered, setHovered] = useState(false);

  const content = label || children;

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^\+?[0-9\s\-()]+$/;

  const isEmail = to && to.includes('@');
  const isContentEmail = !to && typeof content === 'string' && emailRegex.test(content);
  const isPhoneNumber = to && phoneRegex.test(to);
  const isContentPhoneNumber = !to && typeof content === 'string' && phoneRegex.test(content);

  const finalHref = isEmail
    ? `mailto:${to}`
    : isContentEmail
      ? `mailto:${content}`
      : isPhoneNumber
        ? `tel:${to}`
        : isContentPhoneNumber
          ? `tel:${content}`
          : to;

  const isExternalLink =
    finalHref &&
    (finalHref.startsWith('http') ||
      finalHref.startsWith('mailto:') ||
      finalHref.startsWith('tel:') ||
      finalHref.startsWith('/'));
  const isInternalLink = finalHref && !isExternalLink;

  const linkProps: any = {
    component: isInternalLink ? RouterLink : 'a',
    href: !isInternalLink ? finalHref : undefined,
    to: isInternalLink ? finalHref : undefined,
  };

  if (!isInternalLink && download) {
    // Проверяем поддержку атрибута download
    const isDownloadSupported = typeof document !== 'undefined' && 'download' in document.createElement('a');
    if (isDownloadSupported) {
      linkProps.download = download;
    } else {
      // Если не поддерживается, открываем в новой вкладке
      linkProps.target = '_blank';
      linkProps.rel = 'noopener noreferrer';
    }
  }

  return (
    <MuiLink
      {...linkProps}
      underline="none"
      color={color}
      variant={variant}
      className={className}
      onClick={onClick}
      sx={{
        textDecoration: 'none',
        position: 'relative',
        display: 'inline-block',
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: -2,
          left: 0,
          width: '100%',
          height: '2px',
          backgroundColor: underlineColor,
          transform: hovered ? 'scaleX(1)' : 'scaleX(0)',
          transformOrigin: hovered ? 'left' : 'right',
          transition: 'transform 0.3s ease-in-out',
        },
        ...sx,
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {content}
    </MuiLink>
  );
};

export default HoverLink;
