import React, { useEffect } from 'react';
import Company from '../components/Company/Company';
import { useNavbarStyle } from '../components/NavbarStyleContext';

const CompanyPage: React.FC = () => {
  const { setNavbarStyle } = useNavbarStyle();

  useEffect(() => {
    setNavbarStyle('light');

    return () => {
      setNavbarStyle('dark');
    };
  }, [setNavbarStyle]);

  return <Company />;
};

export default CompanyPage;
