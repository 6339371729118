import React from 'react';
import parse, {
  domToReact,
  DOMNode as HtmlDomNode,
  Element as HtmlElement,
} from 'html-react-parser';
import { Typography, Box } from '@mui/material';
import HoverLink from '../HoverLink';

interface ProductTextBlockProps {
  title?: string;
  content: string;
}

function isElement(node: HtmlDomNode): node is HtmlElement {
  return node.type === 'tag';
}

const TextBlock: React.FC<ProductTextBlockProps> = ({ title, content }) => {
  return (
    <Box>
      {title && (
        <Typography variant="h5" gutterBottom>
          {parse(title, {
            replace: (domNode: HtmlDomNode) => {
              if (isElement(domNode) && domNode.name === 'hoverlink') {
                const innerContent = domToReact(domNode.children as HtmlDomNode[]);
                const { href, download } = domNode.attribs || {};

                return (
                  <HoverLink to={href} download={download} variant="body1">
                    {innerContent}
                  </HoverLink>
                );
              }
            },
          })}
        </Typography>
      )}
      <Typography variant="body1">
        {parse(content, {
          replace: (domNode: HtmlDomNode) => {
            if (isElement(domNode) && domNode.name === 'hoverlink') {
              const innerContent = domToReact(domNode.children as HtmlDomNode[]);
              const { href, download } = domNode.attribs || {};

              return (
                <HoverLink to={href} download={download} variant="body1">
                  {innerContent}
                </HoverLink>
              );
            }
          },
        })}
      </Typography>
    </Box>
  );
};

export default TextBlock;
