// src/components/HowToBuy/WarrantySection.tsx

import React from 'react';
import { Typography, Paper, Box, Divider } from '@mui/material';
import { Fade } from '@mui/material';

const WarrantySection: React.FC = () => {
  return (
    <Fade in={true} timeout={1000}>
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          marginBottom: 4,
          borderRadius: 8,
          transition: 'box-shadow 0.5s ease',
          boxShadow: 3,
          '&:hover': {
            boxShadow: 6,
          },
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
          Гарантия
        </Typography>

        <Divider sx={{ mb: 2 }} />

        <Typography variant="body1" paragraph>
          Для разных категорий товаров предусмотрен различный гарантийный период. На оборудование, которое мы продаем, действует гарантия не менее <em>18 месяцев</em>. А для ряда категорий оборудования она составляет <em>48 месяцев</em>.
        </Typography>
        <Typography variant="body1">
          Это лишний раз подчеркивает, что мы поставляем исключительно надежное и профессиональное оборудование, для решения возникших проблем по работе на поставленном нами оборудовании.
        </Typography>
      </Paper>
    </Fade>
  );
};

export default WarrantySection;
