// src/components/Contacts/ContactSection.tsx

import React from 'react';
import { Typography, Paper, List, ListItem, ListItemText, Box, Divider } from '@mui/material';
import { Fade } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HoverLink from '../HoverLink';

const ContactSection: React.FC = () => {
  return (
    <Fade in={true} timeout={1000}>
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          marginBottom: 4,
          borderRadius: 8,
          transition: 'box-shadow 0.5s ease',
          boxShadow: 3,
          '&:hover': {
            boxShadow: 6,
          },
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
          Контактная информация
        </Typography>

        <Divider sx={{ mb: 2 }} />

        <List>
          <ListItem>
            <ListItemText
              primary="ООО «Торговая компания Юджен»"
              sx={{ fontWeight: 'bold', textAlign: 'center' }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <>
                  Телефон в Шанхае:{' '}
                  <HoverLink
                    to="tel:+8615502106310"
                    label="+86 155-021-063-10"
                    underlineColor="black"
                    color="inherit"
                    variant="body1"
                  />
                </>
              }
              sx={{ textAlign: 'center' }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <>
                  Телефон представителя в РФ:{' '}
                  <HoverLink
                    to="tel:+79108364995"
                    label="+7 910 836-49-95"
                    underlineColor="black"
                    color="inherit"
                    variant="body1"
                  />
                  ,{' '}
                  <HoverLink
                    to="tel:+79109351152"
                    label="+7 910 935-11-52"
                    underlineColor="black"
                    color="inherit"
                    variant="body1"
                  />
                </>
              }
              sx={{ textAlign: 'center' }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <>
                  Email:{' '}
                  <HoverLink
                    to="mailto:yuzhen.torg@gmail.com"
                    label="yuzhen.torg@gmail.com"
                    underlineColor="black"
                    color="inherit"
                    variant="body1"
                  />
                </>
              }
              sx={{ textAlign: 'center' }}
            />
          </ListItem>
        </List>

        {/* Раздел с временем работы */}
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            <AccessTimeIcon fontSize="small" sx={{ mr: 1 }} />
            Время работы
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
            Понедельник - Пятница: 9:00 - 18:00
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
            Суббота и Воскресенье — выходные дни
          </Typography>
        </Box>
      </Paper>
    </Fade>
  );
};

export default ContactSection;
