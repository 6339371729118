import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_BASE_URL } from '../../config/api';
import api from '../../services/api';

interface ICardSpecs {
  [key: string]: string;
}

export interface IProduct {
  id: string;
  name: string;
  image: string;
  description: string;
  categoryId: string;
  card_specs: ICardSpecs;
}

export type IContentBlock = ITextBlock | ITableBlock | IListBlock | IImageGalleryBlock | IFileBlock | ITitle | ICombinedBlock | IDivider;

export interface ICombinedBlock {
  type: 'combined';
  content: Array<(ITextBlock | ITableBlock | IListBlock | IImageGalleryBlock | IFileBlock | ITitle | IDivider)>;
}

export interface IDivider {
  type: 'divider';
}

export interface ITextBlock {
  type: 'text';
  title?: string;
  content: string;
}

export interface ITitle {
  type: 'title';
  text: string;
  level: number;
}

export interface ITableBlock {
  type: 'table';
  title?: string;
  headers: (string | ITableCellData)[];
  rows: Array<(string | ITableCellData)[]>;
}

export interface ITableCellData {
  content: string;
  colspan?: number;
  rowspan?: number;
  align?: 'left' | 'center' | 'right';
  verticalAlign?: 'top' | 'middle' | 'bottom';
}

export interface IListBlock {
  type: 'list';
  title?: string;
  items: string[];
}

export interface IImageGalleryBlock {
  type: 'imageGallery';
  images: string[];
}

export interface IFileBlock {
  type: 'file';
  title?: string;
  files: {
    name: string;
    url: string;
  }[];
}

export interface IProductDetails {
  id: string;
  name: string;
  mainImages: string[];
  contentBlocks: IContentBlock[];
}

interface IProductState {
  byCategory: {
    [categoryId: string]: {
      products: IProduct[];
      loading: boolean;
      error: string | null;
    };
  };
  productDetails: {
    [productId: string]: {
      details: IProductDetails | null;
      loading: boolean;
      error: string | null;
    };
  };
  productInfo: {
    [productId: string]: {
      product: IProduct | null;
      loading: boolean;
      error: string | null;
    };
  };
}

const initialState: IProductState = {
  byCategory: {},
  productDetails: {},
  productInfo: {},
};

export const fetchProductInfo = createAsyncThunk(
  'products/fetchProductInfo',
  async (productId: string) => {
    try {
      const { data } = await api.get(`/api/products/${productId}`);
      return { productId, product: data as IProduct };
    } catch (error: any) {
      if (error.response?.status === 404) {
        throw new Error('Продукт не найден');
      }
      throw new Error('Ошибка при загрузке информации о продукте');
    }
  }
);

export const fetchProductsByCategory = createAsyncThunk(
  'products/fetchByCategory',
  async (categoryId: string) => {
    try {
      const { data } = await api.get(`/api/categories/${categoryId}/products`);
      return { categoryId, products: data as IProduct[] };
    } catch (error: any) {
      if (error.response?.status === 404) {
        return { categoryId, products: [] };
      }
      throw new Error('Ошибка при загрузке товаров');
    }
  }
);

export const fetchProductDetails = createAsyncThunk(
  'products/fetchProductDetails',
  async (productId: string) => {
    try {
      const { data } = await api.get(`/api/products/${productId}/details`);
      return { productId, details: data as IProductDetails };
    } catch (error: any) {
      if (error.response?.status === 404) {
        throw new Error('Продукт не найден');
      }
      throw new Error('Ошибка при загрузке деталей продукта');
    }
  }
);

const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Обработка fetchProductsByCategory
    builder
      .addCase(fetchProductsByCategory.pending, (state, action) => {
        const categoryId = action.meta.arg;
        if (!state.byCategory[categoryId]) {
          state.byCategory[categoryId] = {
            products: [],
            loading: true,
            error: null,
          };
        } else {
          state.byCategory[categoryId].loading = true;
          state.byCategory[categoryId].error = null;
        }
      })
      .addCase(fetchProductsByCategory.fulfilled, (state, action) => {
        const { categoryId, products } = action.payload;
        state.byCategory[categoryId] = {
          products,
          loading: false,
          error: null,
        };
      })
      .addCase(fetchProductsByCategory.rejected, (state, action) => {
        const categoryId = action.meta.arg;
        if (!state.byCategory[categoryId]) {
          state.byCategory[categoryId] = {
            products: [],
            loading: false,
            error: action.error.message || 'Ошибка',
          };
        } else {
          state.byCategory[categoryId].loading = false;
          state.byCategory[categoryId].error = action.error.message || 'Ошибка';
        }
      });

    // Обработка fetchProductDetails
    builder
      .addCase(fetchProductDetails.pending, (state, action) => {
        const productId = action.meta.arg;
        if (!state.productDetails[productId]) {
          state.productDetails[productId] = {
            details: null,
            loading: true,
            error: null,
          };
        } else {
          state.productDetails[productId].loading = true;
          state.productDetails[productId].error = null;
        }
      })
      .addCase(fetchProductDetails.fulfilled, (state, action) => {
        const { productId, details } = action.payload;
        state.productDetails[productId] = {
          details,
          loading: false,
          error: null,
        };
      })
      .addCase(fetchProductDetails.rejected, (state, action) => {
        const productId = action.meta.arg;
        if (!state.productDetails[productId]) {
          state.productDetails[productId] = {
            details: null,
            loading: false,
            error: action.error.message || 'Ошибка',
          };
        } else {
          state.productDetails[productId].loading = false;
          state.productDetails[productId].error = action.error.message || 'Ошибка';
        }
      });
    builder
      .addCase(fetchProductInfo.pending, (state, action) => {
        const productId = action.meta.arg;
        if (!state.productInfo[productId]) {
          state.productInfo[productId] = {
            product: null,
            loading: true,
            error: null,
          };
        } else {
          state.productInfo[productId].loading = true;
          state.productInfo[productId].error = null;
        }
      })
      .addCase(fetchProductInfo.fulfilled, (state, action) => {
        const { productId, product } = action.payload;
        state.productInfo[productId] = {
          product,
          loading: false,
          error: null,
        };
      })
      .addCase(fetchProductInfo.rejected, (state, action) => {
        const productId = action.meta.arg;
        if (!state.productInfo[productId]) {
          state.productInfo[productId] = {
            product: null,
            loading: false,
            error: action.error.message || 'Ошибка при загрузке информации о продукте',
          };
        } else {
          state.productInfo[productId].loading = false;
          state.productInfo[productId].error = action.error.message || 'Ошибка при загрузке информации о продукте';
        }
      });
  },
});

export default productSlice.reducer;
