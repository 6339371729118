// src/components/Company/LicensesSection.tsx

import React, { useState } from 'react';
import { Typography, Paper, Grid, Box, Divider, Dialog, DialogContent, Button, IconButton } from '@mui/material';
import { Fade } from '@mui/material';
import { SaveAlt as SaveIcon, Close as CloseIcon, PictureAsPdf as PdfIcon } from '@mui/icons-material';

const LicensesSection: React.FC = () => {
  const [openImage, setOpenImage] = useState<string | null>(null);
  const [imageLabel, setImageLabel] = useState<string | null>(null);

  const handleImageClick = (src: string, label: string) => {
    setOpenImage(src);
    setImageLabel(label);
  };

  const handleClose = () => {
    setOpenImage(null);
    setImageLabel(null);
  };

  const handleDownload = () => {
    if (openImage) {
      const link = document.createElement('a');
      link.href = openImage;
      link.download = imageLabel ? `${imageLabel}.jpg` : 'license.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleDownloadPdf = () => {
    const link = document.createElement('a');
    link.href = 'https://s3.timeweb.cloud/b510c84d-test/company/license.pdf';
    link.download = 'license.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Fade in={true} timeout={1000}>
      <Box sx={{ mb: 4 }}>
        <Paper
          elevation={3}
          sx={{
            padding: 4,
            borderRadius: 8,
            backgroundColor: 'background.paper',
            transition: 'box-shadow 0.3s ease',
            boxShadow: 3,
            '&:hover': {
              boxShadow: 6,
            },
          }}
        >
          <Typography variant="h5" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold' }}>
            Лицензии
          </Typography>

          <Divider sx={{ mb: 3 }} />

          <Typography variant="body1" sx={{ mt: 2, mb: 3 }}>
            Компания зарегистрирована в Шанхае и имеет все необходимые разрешения для осуществления своей деятельности. Основная информация о лицензии:
          </Typography>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'stretch',
              flexWrap: 'wrap',
              gap: 4,
            }}
          >
            <Box
              sx={{
                width: { xs: '100%', md: '45%' },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box
                component="img"
                src="https://s3.timeweb.cloud/b510c84d-test/company/license_ru.jpeg"
                alt="Нотариально заверенный перевод Бизнес-лицензии КНР"
                sx={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: 2,
                  cursor: 'pointer',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
                onClick={() => handleImageClick('https://s3.timeweb.cloud/b510c84d-test/company/license_ru.jpeg', 'Нотариально заверенный перевод Бизнес-лицензии КНР')}
              />
              <Typography variant="subtitle1" align="center" sx={{ mt: 2 }}>
                Нотариально заверенный перевод Бизнес-лицензии КНР
              </Typography>
            </Box>

            <Box
              sx={{
                width: { xs: '100%', md: '45%' },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box
                component="img"
                src="https://s3.timeweb.cloud/b510c84d-test/company/license_cn.jpg"
                alt="Лицензия на торговлю в Китае"
                sx={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: 2,
                  cursor: 'pointer',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
                onClick={() => handleImageClick('https://s3.timeweb.cloud/b510c84d-test/company/license_cn.jpg', 'Лицензия на торговлю в Китае')}
              />
              <Typography variant="subtitle1" align="center" sx={{ mt: 2 }}>
                Лицензия на торговлю в Китае
              </Typography>
            </Box>

            <Box
              sx={{
                width: { xs: '100%', md: '45%' },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="subtitle1" align="center" sx={{ mt: 2 }}>
                Лицензия в формате PDF
              </Typography>
              <Button
                variant="contained"
                onClick={handleDownloadPdf}
                sx={{
                  mt: 1,
                  color: 'white',
                  backgroundColor: 'rgba(0, 0, 0, 0.8)', // Сделаем кнопку темнее для контраста с белым фоном
                  borderRadius: '10px',
                  backdropFilter: 'blur(5px)',
                  padding: '10px 20px',
                  fontSize: '16px',
                  transition: 'background-color 0.3s ease, transform 0.3s ease',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.6)', // Цвет при наведении будет немного светлее
                    transform: 'scale(1.05)', // Легкий эффект увеличения при наведении
                  },
                }}
              >
                Скачать PDF
              </Button>
            </Box>
          </Box>
        </Paper>

        {/* Диалоговое окно для открытия изображений */}
        <Dialog open={!!openImage} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogContent sx={{ position: 'relative' }}>
            {/* Кнопка закрытия */}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>

            {imageLabel && (
              <Typography variant="h6" gutterBottom sx={{ textAlign: 'center', my: 1 }}>
                {imageLabel}
              </Typography>
            )}

            {openImage && (
              <img
                src={openImage}
                alt={imageLabel || 'Лицензия'}
                style={{
                  width: '100%',
                  height: 'auto',
                  marginBottom: '16px',
                }}
              />
            )}

            <Box sx={{ textAlign: 'center' }}>
              <Button variant="contained" startIcon={<SaveIcon />} onClick={handleDownload}>
                Скачать
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </Fade>
  );
};

export default LicensesSection;
